<template>
  <div class="all-product-class">
    <p class="left">
      <span @mouseenter="hoverHandler" @mouseleave="mouseleaveHandler()">
        <svg-icon name="layout-grid" class-name="layout-grid-icon" />全部商品分类<Icon type="ios-arrow-forward" class="ios-arrow-forward-cion" />
      </span>
    </p>
    <div class="right">
      <Tabs :list="tabList" :selected-type="selectedType" @change="tabsChangehandler" />
    </div>
    <div v-show="showModal" class="modal" @mouseleave="modalMouseleaveHandler" @mouseenter="modalHoverHandler()">
      <div class="first">
        <div
          v-for="item in categoryList"
          :key="item.value"
          class="first-item"
          :class="detailId == item.value && 'hover'"
          @mouseleave="firstMouseleaveHandler"
          @mouseenter="firstHoverHandler(item.value)"
        >
          <p class="first-name" @click="routerTo('/search/list?categoryId=' + item.value)">{{ item.title }}</p>
          <Icon type="ios-arrow-forward" class="first-name-cion" />
        </div>
      </div>
      <div class="modal-warp">
        <div v-for="detail in detailData" :key="detail.id" class="two-item">
          <span class="two-name" @click="routerTo('/search/list?categoryId=' + detail.id)">{{ detail.categoryName }} <Icon type="ios-arrow-forward" /></span>
          <div class="three-warp">
            <div v-for="threeData in detail.children" :key="threeData.id" class="three-item" @click="routerTo('/search/list?categoryId=' + threeData.id)">
              {{ threeData.categoryName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPcTabData, obtainClassification } from '@/common/service/home'
import { getToken } from '@/common/utils'

import { cmsLink } from '@/views/home/index'
import Tabs from '@/components/Tabs'
import { isEmpty } from 'lodash'
export default {
  name: 'AllProductClass',
  components: {
    Tabs
  },
  props: {
    selectedType: {
      type: String,
      default: 'home'
    }
  },
  data() {
    return {
      tabList: [],
      detailData: [],
      classChildData: {},
      showModal: false,
      detailName: '',
      detailId: '',
      categoryList: [],
      loads: false
    }
  },
  watch: {
    '$store.state.home.classData': {
      handler(list) {
        this.handelGetCategoryList()
      },
      deep: true
    }
  },
  created() {
    this.list = {}
    if (sessionStorage.getItem('tabList')) {
      this.tabList = JSON.parse(sessionStorage.getItem('tabList')).tabList
    } else {
      this.getPcTabData()
    }
  },
  mounted() {
    this.handelGetCategoryList()
  },
  methods: {
    handelGetCategoryList() {
      const categoryList = this.$store.state.home?.classData || []
      if (categoryList.length !== 0) {
        if (this.loads) return
        this.loads = true
        this.categoryList = categoryList
        this.detailId = this.categoryList[0]?.value || ''
        const ids = []
        categoryList.map((item) => {
          ids.push(item.value)
        })
        if (!getToken()) return
        obtainClassification(ids).then((res) => {
          const result = {}
          const list = res.data || []
          for (let i = 0; i < list.length; i++) {
            const item = list[i]
            const id = item.id
            result[id] = item.children || []
          }
          if (list.length !== 0) {
            this.detailData = result[this.detailId] || []
            this.classChildData = result
          }
        }).finally(() => {
          this.loads = false
        })
      }
    },
    tabsChangehandler(data) {
      // eslint-disable-next-line eqeqeq
      if (data?.name == '全部商品') {
        this.$router.push('/search/list?type=homeShop')
      } else {
        cmsLink.bind(this)(data.link)
      }
    },
    async getPcTabData() {
      const { data } = await getPcTabData()
      this.tabList = [...data.configContent.defaultList, ...data.configContent.list]
      sessionStorage.setItem('tabList', JSON.stringify({ tabList: this.tabList }))
    },
    async hoverHandler() {
      this.timer && clearTimeout(this.timer)
      this.showModal = true
    },
    mouseleaveHandler() {
      this.timer = setTimeout(() => {
        this.showModal = false
      }, 200)
    },
    modalMouseleaveHandler() {
      this.mouseleaveHandler()
    },
    modalHoverHandler() {
      this.timer && clearTimeout(this.timer)
    },
    firstMouseleaveHandler() {},
    firstHoverHandler(id) {
      this.detailId = id
      this.detailData = null
      if (!isEmpty(this.classChildData)) {
        this.detailData = this.classChildData[id]
      }
    },
    routerTo(path) {
      this.$router.push(path)
    }
  }
}
</script>
<style scoped lang="less">
.all-product-class {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  padding-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: relative;
}
.left {
  width: 274px;
}
.right {
  margin-left: 12px;
  flex: 1;
}
::v-deep .root {
  height: 100% !important;
  margin-bottom: 0px !important;
  & .tabs-list *:nth-child(2) {
    display: none;
  }
}
::v-deep .content {
  width: auto !important;
  padding-top: 0px !important;
}
::v-deep .content .tabs {
  .tabs-list .selectde-tab {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.9) !important;
    font-weight: 500 !important;
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 70%;
      height: 4px;
      border-radius: 10px;
      background-color: #f99d33;
    }
  }
  .tabs-item {
    font-weight: 400 !important;
  }
}
.layout-grid-icon {
  margin-right: 8px;
}
.ios-arrow-forward-cion {
  transform: rotate(90deg);
  margin-left: 4px;
}
.modal {
  width: 826px;
  // height: 520px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 56px;
  box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.12), 5px -5px 10px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-width: 0;
  .first {
    width: 150px;
    height: 424px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(#eee);
    background: var(#fff);
    box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    .first-item {
      padding: 8px;
      box-sizing: border-box;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover,
      &.hover {
        background-color: #f5f5f5;
        .first-name-cion {
          display: block;
        }
      }
    }
    .first-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      &:hover {
        color: #ce7720;
      }
    }
    .first-name-cion {
      display: none;
    }
  }
  .modal-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.9);
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  .modal-warp {
    padding: 8px 24px;
    flex: 1;
    height: 424px;
    overflow-y: auto;
  }
  .two-name {
    width: 82px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    &:hover {
      color: #ce7720;
    }
  }
  .two-item {
    padding: 16px 0 10px;
    display: flex;
    border-style: solid;
    border-color: #e7e7e7;
    border-width: 0 0 1px 0;
    &:last-child {
      border-bottom-width: 0;
    }
  }
  .three-warp {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .three-item {
    margin: 0 16px 6px 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      color: #ce7720;
    }
  }
}
</style>
