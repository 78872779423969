<template>
  <Modal v-model="modal" :title="type === 'normal' ? '缺货登记' : '缺货登记提醒'" :loading="loading" ok-text="提交登记" @on-ok="handleSubmit">
    <div class="detail" v-if="type === 'normal'">
      <div class="item">
        <div class="label">登记商家：</div>
        <div class="value">{{ detailData.orgName }}</div>
      </div>
      <div class="item">
        <div class="label">登记商品：</div>
        <div class="value">
          <div class="goods">
            <img class="goods-img" :src="getFilePath('PS_01', detailData.mainPicUrl) || defaultPath" alt="" />
            <div class="goods-info">
              <p class="name">{{ detailData.generalChineseName }}{{ detailData.productSpec }}</p>
              <!-- <p>{{ detailData.productSpec }}</p> -->
              <p class="sub-name">{{ detailData.manufactureCompany }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item center">
        <div class="label">需求数量：</div>
        <div class="value">
          <InputNumber v-model="qty" controls-outside :min="1" :precision="0" />
        </div>
      </div>
    </div>
    <div class="detail" v-else>
      <div class="car-text">
        已加入购物车
        <span>{{ detailData.carNum }}</span>
        件，剩余
        <span class="rest">{{ detailData.restNum }}</span>
        件，是否提交缺货登记？
      </div>
    </div>
  </Modal>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { commitPrdRegister } from '@/common/service/news'
import defaultPath from '@/assets/images/common/good_default.png'
export default {
  name: 'UserFooter',
  data() {
    return {
      getFilePath,
      modal: false,
      loading: true,
      detailData: {},
      qty: 1,
      defaultPath,
      type: 'normal' // normal 正常缺货登记，car 购物车缺货登记
    }
  },
  methods: {
    init(data, type = 'normal') {
      this.detailData = data
      this.modal = true
      this.type = type
      this.qty = data.restNum || 1
    },
    asyncOK() {
      setTimeout(() => {
        this.modal = false
      }, 2000)
    },
    async handleSubmit() {
      if (!this.qty) {
        this.$Message.error('请输入需求数量')
        return
      }
      const { orgId, generalChineseName, productSpec, manufactureCompany, productId, productNo } = this.detailData
      const parpms = {
        orgId,
        generalChineseName, // 商品名称
        productSpec, // 包装规格
        manufactureCompany, // 厂家
        qty: this.qty, // 数量
        productId,
        productNo
      }
      try {
        const data = await commitPrdRegister(parpms)
        if (data?.code === 200) {
          this.$Message.success('登记成功')
          this.modal = false
          this.$emit('success')
          this.$zhuge.track('缺货登记-提交商品缺货登记', {
            "商家名称": this.detailData?.orgName || '',
            "商品名称": parpms?.generalChineseName || '',
            "包装规格": parpms?.productSpec || '',
            "生产企业": parpms?.manufactureCompany || '',
            '需求数量': parpms?.qty || '0'
          })
        }
        this.loading = false
      } catch (error) {
        console.log("error", error)
        this.$Message.error(error?.data?.message)
        this.loading = false
        this.modal = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.detail {
  .item {
    display: flex;
    margin-bottom: 16px;

    &.center {
      align-items: center;
    }
    .label {
      width: 100px;
      text-align: right;
      color: rgba(0, 0, 0, 0.9);
    }
    .value {
      flex: 1;
      padding-left: 8px;
      color: rgba(0, 0, 0, 0.9);
    }
    .goods {
      display: flex;
      .goods-img {
        width: 80px;
        height: 80px;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
      }
      .goods-info {
        padding-left: 10px;
        p {
          margin: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          &.name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &.sub-name {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            padding-top: 4px;
          }
        }
      }
    }
  }
  .car-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    padding: 12px 0;
    span {
      padding: 0 6px;
    }
    .rest {
      color: #cb272d;
    }
  }
  :deep(.ivu-input-number-input) {
    text-align: center !important;
  }
}
</style>
