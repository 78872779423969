<template>
  <div ref="Events" class="events">
    <template v-if="currentRoutePath">
      <template v-if="imgUrl">
        <img ref="EventImg" class="event-img" :src="imgUrl">
      </template>
      <template v-if="coupon">
        <template v-if="$route.path !== '/detail'">
          <div class="coupon_list coupon">
            <p class="text">{{ coupon.text }}</p>
            <div class="content-num">
              <div v-if="coupon.unitLeft" class="unit">¥</div>
              <div class="num" :style="{fontSize: handelCountWList(20, coupon.couponLabel) + 'px' }">{{ coupon.couponLabel }}</div>
              <div v-if="coupon.unitRight" class="unit">折</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="coupon_detail coupon">
            <p class="text">{{ coupon.text }}</p>
            <div class="content-num">
              <div class="unit">{{ coupon.unit }}</div>
              <div v-if="coupon.unitLeft" class="unit">¥</div>
              <div class="num" :style="{fontSize: handelCountW(22, coupon.couponLabel) + 'px' }">{{ coupon.couponLabel }}</div>
              <div v-if="coupon.unitRight" class="unit">折</div>
            </div>
          </div>
        </template>
      </template>
    </template>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Events',
  props: {
    row: {
      type: Object,
      default: () => (null)
    },
    shop: { // 传参控制显示
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 拼团、润划算、近效期、组合活动
      activityList: {
        '2': {
          name: '润划算_开始',
          imgs: ['/img/Event/rhs_yes.png', '/img/Event/rhs_no.png'],
          imgx: ['/img/Event/rhs_yes_x.png', '/img/Event/rhs_no_x.png']
        },
        '6': {
          name: '拼团',
          imgs: ['/img/Event/pt.png'],
          imgsx: ['/img/Event/pt_x.png']
        },
        '5': {
          name: '近效期',
          imgs: ['/img/Event/jxq.png'],
          imgsx: ['/img/Event/jxq_x.png']
        },
        '4': {
          name: '组合活动',
          imgs: ['/img/Event/zhg.png'],
          imgsx: ['/img/Event/zhg_x.png']
        },
        '41': {
          name: '组合活动',
          imgs: ['/img/Event/zhg.png'],
          imgsx: ['/img/Event/zhg_x.png']
        },
        '42': {
          name: '组合活动',
          imgs: ['/img/Event/zhg.png'],
          imgsx: ['/img/Event/zhg_x.png']
        }
      },
      imgUrl: '',
      coupon: null
    }
  },
  computed: {
    currentRoutePath() { // 显示位置控制
      if (this.shop) { // 商家列表下面的全部商品
        return true
      }
      const route = this.$route.path
      const list = [
        '/search/list', // 全部商品 检索
        '/activity/groupCenter', // 拼团专区
        '/center/collection', // 我的收藏
        '/center/footmark', // 我的足迹
        '/center/purchaseList', // 常购清单
        '/center/info', // 个人中心
        '/coupon/goodslist', // 去使用,去凑券
        '/detail', // 商品详情
        '/more', // 更多列表
        '/activity/priceDownCenter', // 润划算专区更多
        '/shopHome' // 店铺首页
      ]
      return list.includes(route)
    },
    currentData() {
      const activityType = this.activityType
      return this.activityList[activityType] || {}
    }
  },
  mounted() {
    // 初始化
    this.init()
  },
  methods: {
    init() {
      // 优惠券
      this.getRow((row) => {
        this.handelCouponList(row)
        // 活动
        this.handelMarketing(row)
      })
    },

    // 调用执行
    handelSetRow(row) {
      // this.handelCouponList(row)
      // 活动
      this.handelMarketing(row)
    },
    getRow(back) {
      if (this.row) {
        back(this.row)
        return
      }

      // 在详情部分不显示优惠券后续优化
      // const sessionRow = JSON.parse(sessionStorage.getItem('Events_goodsItem'))
      // if (sessionRow) {
      //   back(sessionRow)
      //   setTimeout(() => {
      //     sessionStorage.removeItem('Events_goodsItem')
      //   }, 3000)
      //   return
      // }
    },
    handelCountW(size, str) {
      const vl = String(str).length
      const sizeList = {
        4: '18',
        5: '14',
        6: '12',
        7: '11',
        8: '10'
      }
      if (sizeList[vl]) {
        return sizeList[vl]
      } else if (vl.length > 8) {
        return 10
      } else {
        return size
      }
    },
    handelCountWList(size, str) {
      const vl = String(str).length
      const sizeList = {
        3: '16',
        4: '14',
        5: '11',
        6: '10',
        7: '9',
        8: '8',
        9: '7'
      }
      if (sizeList[vl]) {
        return sizeList[vl]
      } else {
        return size
      }
    },
    // 优惠券相关
    handelCouponList(row = null) {
      if (!row) return
      const couponList = row?.couponList || []
      if (couponList.length === 0) return

      // couponType 1 单品券 2 分类券
      const coupon = couponList[couponList.length - 1]
      let key = ''
      if (coupon.couponType === 'coupon_commodity') {
        // 商品券 注意现在没有 org_category: '分类券' manufactor: '厂家券' 只剩下 product单品券
        key = 'product'
      } else {
        key = coupon.couponType || ''
      }
      const exp = {
        'product': {
          text: '商品'
        },
        'coupon_full_decrement': {
          text: '满减'
        },
        'coupon_full_discount': {
          text: '满折'
        },
        'coupon_full_rebate': {
          text: '满返'
        },
        'coupon_new_customer': {
          text: '新客'
        },
        'coupon_postage': {
          text: '运费'
        }
      }
      if (!key || !exp[key] || !coupon.couponLabel) return null
      const temp = {
        text: exp[key].text || null
      }
      const sc = ['减', '享', '返', '元']
      // const mock = ['立减20.11券', '189减20.11券', '立享5.33折券', '200享5折券', '200返20券', '返5元券', '新客200.00减20.00券', '运费减5.1券']
      const mock = [coupon.couponLabel]
      const list = []
      mock.map((str) => {
        sc.map((first) => {
          if (str.indexOf(first) !== -1) {
            const last = str.indexOf(first)
            const step2 = str.slice(last)
            if (step2.indexOf('折') !== -1) {
              const result = step2.replace(/[^\d.]/g, '')
              temp.unitRight = '折'
              list.push(result)
            } else {
              const result = step2.replace(/[^\d.]/g, '')
              temp.unitLeft = '¥'
              list.push(result)
            }
          }
        })
      })
      temp.couponLabel = Number(list[0]) || ''
      if (temp.couponLabel) {
        this.coupon = temp
        return
      }
      this.coupon = null
    },
    // 活动初始化
    handelMarketing(row = null) {
      if (!row) return
      const marketing = this.getMarketing(row)
      if (!marketing) return
      const keys = Object.keys(this.activityList) // 所以活动CODELIST
      const activityType = String(marketing?.activityType || '') // 所以类型
      const currentData = this.activityList[activityType] || {}

      this.activityType = activityType || ''
      if (!(keys.includes(activityType))) return
      // 逻辑开始
      const preTime = marketing.preTime || ''

      // 优惠券处理逻辑
      // 图片外框逻辑
      if (activityType === '2' && preTime && new Date().getTime() < marketing.startTime) { // 润划算处理逻辑
        this.imgUrl = currentData.imgs[1]
      } else {
        this.imgUrl = currentData.imgs[0]
      }
      // 计算当前商品展示宽高
      setTimeout(this.handelGetDomInfo, 100)
    },
    // 图片浮层处理
    getMarketing(row = {}) {
      // 活动字段处理
      const marketing = row.marketing
      if (marketing) return marketing || null
      const promotionMarketing = row.promotionMarketing || []
      if (promotionMarketing.length === 0) return null
      // v1.37 只考虑组合购情况 41, 42
      const exp = [41, 42]
      for (let i = 0; i < promotionMarketing.length; i++) {
        const item = promotionMarketing[i]
        if (exp.includes(item.activityType)) {
          return item
        }
      }
      // 具体盘文博 优惠券数据怎么取
      return null
    },
    getCurrentType() {
      const activityType = this.activityType
      for (let i = 0; i < this.activityList.length; i++) {
        const item = this.activityList[i]
        if (item.type === activityType) {
          return item
        }
      }
      return null
    },
    handelGetDomInfo() {
      const oParent = this.$refs?.Events?.parentNode || null
      if (!oParent) return
      const oEventImg = this.$refs.EventImg
      if (!oEventImg) return
      oEventImg.style.width = `${oParent.offsetWidth}px`
      oEventImg.style.height = `${oParent.offsetHeight}px`
      oEventImg.style.display = 'block'
    }
  }
}
</script>

<style scoped lang="less">
.events {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  .event-img {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  .coupon {
    background:url("/img/Event/coupon.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .coupon_list {
    width: 48px;
    height: 52px;
    position: absolute;
    right: 8px;
    top: 8px;
    .text {
      font-size: 7px;
      margin-top: 2.5px;
      color: #E90028;
      font-family: "PingFang SC";
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: 5px; /* 71.429% */
    }
    .content-num {
      height: 24px;
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      margin-top: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .num {
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
    }
    .unit {
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-size: 10px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

     .coupon_detail {
    width: 60px;
    height: 66px;
    position: absolute;
    right: 16px;
    top: 16px;
    .text {
      font-size: 7px;
      margin-top: 3.5px;
      color: #E90028;
      font-family: "PingFang SC";
      font-size: 7px;
      font-style: normal;
      font-weight: 500;
      line-height: 5px; /* 71.429% */
    }
    .content-num {
      height: 30px;
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      margin-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .num {
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
    }
    .unit {
      color: #FFFDFC;
      font-family: "PingFang SC";
      font-size: 12px !important;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>
