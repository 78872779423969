<template>
  <div class="platform-tag-outer">

    <!-- 医保 -->
    <img
        v-if="goodsItem?.isMedical"
        class="platform-tag"
        src="~@/assets/images/global/medicalL.png"
        alt=""
        style="width: 32px;height: 16px"
    >
    <!--处方药-->
    <img v-else-if="rx" class="platform-tag" src="~@/assets/images/global/rxL.png" alt="">
    <!-- 冷链-->
    <img v-else-if="coldChain" class="platform-tag" src="~@/assets/images/global/cold-chainL.png" alt="">

    <!-- 基药 -->
    <img
      v-else-if="isCountryBasicDrugFlag"
      class="platform-tag"
      src="~@/assets/images/global/jyL.png"
      alt=""
      style="width: 32px;height: 16px"
    >
  </div>
</template>
<script>
/*
* 平台标签展示
* */
export default {
  name: 'PlatformTag',
  props: {
    goodsItem: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    coldChain() {
      // 判断当前商品是否为冷链
      return !!this.goodsItem.isColdChain
    },
    rx() {
      // 判断当前商品是否为处方药
      return String(this.goodsItem.prescriptionType) === '0'
    },
    isCountryBasicDrugFlag() {
      if ((this.goodsItem.isColdChain && (this.goodsItem.prescriptionType && this.goodsItem.prescriptionType == 0) && this.goodsItem.isMedical) && (this.goodsItem?.countryBasicDrugFlag == '1')) {
        return false
      }
      if (this.goodsItem?.countryBasicDrugFlag == '1') {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped lang="less">
.platform-tag{
  height: 16px;
  width: auto;
  margin-right: 4px;
}
.platform-tag-outer{
  display: inline-block;
  vertical-align: middle;
  height: 16px
}
</style>
