import RouterContanst from '@/router/router-contanst'
export default {
  methods: {
    jumpForgetPwd() {
      this.$router.push({
        name: RouterContanst.USER.FORGET_PWD
      })
    },
    jumpRegist() {
      this.$zhuge.track('注册-进入注册页')
      this.$router.push({
        name: RouterContanst.USER.REGIST
      })
    },
    jumpLogin() {
      this.$router.push({
        name: RouterContanst.USER.LOGIN
      })
    }
  }
}
