import { get, post, getToken } from '@/common/utils'
import { api } from '@/common/service/config'

/**
 * 企业入驻
 */

export const setInfoAPI = data =>
  post({
    url: `${api.baseUrl}/cust/custuser/insertCustInfo`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 更新企业信息接口
 * @param {*} data
 * @returns
 */
export const updateCustInfoAPI = data =>
  post({
    url: `${api.baseUrl}/cust/custuser/updateCustInfo`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 搜索企业
 * @param {*} data
 * @returns
 */
export const findCustomerByNameAPI = data =>
  post({
    url: `${api.baseUrl}/cust/custuser/findCustomerByName`,
    data
  })

/**
 * 根据token获取企业信息
 * @returns
 */
export const getMyCustomerInfoAPI = cancelToken =>
  get({
    url: `${api.baseUrl}/cust/custuser/getMyCustomerInfo`,
    config: { cancelToken }
  })
// 获取商家店铺状态
export const getSimpleMyCustomerInfo = () =>
  get({
    url: `${api.baseUrl}/cust/custuser/getSimpleMyCustomerInfo`
  })
/**
 * 根据token获取我的资质
 * @returns
 */
export const getMyCertificateListAPI = () =>
  get({
    url: `${api.baseUrl}/cust/custuser/getMyCertificateList`
  })

/**
 * 根据customerId获取我的资质
 * @returns
 */
export const getCustomerCertificateListAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/getAgentCertificateList?customId=${data}`
  })

/**
 * 根据token获取我的授权委托
 * @returns
 */
export const getMyUserCertificateListAPI = () =>
  get({
    url: `${api.baseUrl}/cust/custuser/getMyUserCertificateList`
  })

/**
 * 删除收货信息
 * @returns
 */
export const deleteAddrById = id =>
  get({
    url: `${api.baseUrl}/cust/custreceiveaddr/deleteAddrById?receiveAddrId=${id}`
  })

/**
 * 删除收货信息，已审核的也可以删除
 * @param {*} data
 * @returns
 */
export const deleteAddrForFrontend = data =>
  post({
    url: `${api.baseUrl}/cust/custreceiveaddr/deleteAddrForFrontend`,
    data
  })

/**
 * 查询建采状态数量
 * @param {*} data
 * @returns
 */
export const countByStateAPI = () =>
  get({
    url: `${api.baseUrl}/cust/custuserrel/countByState`
  })

/**
 * 获取企业类型列表
 * @returns
 */
export const getCustTypeMapAPI = () =>
  post({
    url: `${api.baseUrl}/cust/custuser/getCustTypeList`
  })

/**
 * 获取推荐商家列表
 * @returns
 */
export const getRecommendCustomerList = data =>
  get({
    url: `${api.baseUrl}/org/info/queryOrgInfo`,
    data
  })

/**
 *根据企业类型id获取其他资质类型
 * @returns
 */
export const getCertByCustIdAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/getCertByCustId?custOrgTypeId=${data}`,
    isLoading: true
  })

/**
 *根据企业类型id获取其他资质类型 (没有绑定客户类型的资质)
 * @returns
 */
export const getCertificates = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/getCertificates?custOrgTypeId=${data.custOrgTypeId}${data.customId ? '&customId=' + data.customId : ''}`
  })

/**
 * 根据用户id获取可切换客户列表
 * @param {*} data
 * @returns
 */
export const obtainCustomerListAPI = () =>
  get({
    url: `${api.baseUrl}/v1/wx/obtainCustomerList`
  })

/**
 * 切换商家身份
 * @param {*} data
 * @returns
 */
export const switchUserIdentityAPI = data =>
  post({
    url: `${api.baseUrl}/v1/wx/switchUserIdentity?userType=CUST&id=${data}`,
    isLoading: true
  })

/**
 * 获取商家列表
 * @param {*} data
 * @returns
 */
export const myOrgListAPI = data =>
  post({
    url: `${api.baseUrl}/cust/custuserrel/myOrgList`,
    data,
    isLoading: true
  })

/**
 * 商家拒绝理由
 * @param {*} data
 * @returns
 */
export const refuseReasonAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuserrel/refuseReason?custOrgRelId=${data}`
  })

/**
 * 根据客户id查询关联的企业信息资质信息地址信息
 * @param {*} data
 * @returns
 */
export const getCustInfoAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/getCustInfo?custmerId=${data}`
  })

export const listTreeAPI = () =>
  get({
    url: `${api.baseUrl}/cust/custuser/listTree`
  })

/**
 * 关联企业，已关联时 关联企业的接口
 * @param {*} data
 * @returns
 */
export const bindAccount = data =>
  post({
    url: `${api.baseUrl}/cust/custuser/bindAccount`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })
/**
 * 上传文件
 */
export const uploadFile = filePath => {
  return new Promise((resolve, reject) => {
    uni.uploadFile({
      url: `${api.baseUrl}/common/fileUpload/upload`,
      filePath,
      name: 'file',
      header: {
        Authorization: getToken()
      },
      success(res) {
        resolve(res)
      },
      fail(err) {
        reject(err)
      }
    })
  })
}

/**
 * 营业执照重复效验
 * @param {*} data
 * @returns
 */
export const checkThreeDocMergerAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/checkThreeDocMerger?threeDocMerger=${data}`
  })

/**
 * 企业名称重复效验
 * @param {*} data
 * @returns
 */
export const checkcustomerNameAPI = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/checkcustomerName?customName=${data}`
  })

/**
 * 基础信息变更保存接口
 * @param {*} data
 * @returns
 */
export const updateCustinfoAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateCustInfo`,
    data: { ...data, source: 'user' },
    isLoading: true
  })

/**
 * 新增收获地址接口
 * @param {*} data
 * @returns
 */
export const insertReceiveAddrAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/insertReceiveAddr`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 修改收获地址接口
 * @param {*} data
 * @returns
 */
export const updateReceiveAddrAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateReceiveAddr`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 新增开票信息接口
 * @param {*} data
 * @returns
 */
export const insertInvoiceAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/insertInvoice`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 更新资质信息接口
 * @param {*} data
 * @returns
 */
export const updateCertAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateCert`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 更新委托人信息接口
 * @param {*} data
 * @returns
 */
export const updateClientAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateClient`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

export const updateInvoiceAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateInvoice`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * 获取审批节点列表
 */
export const getApprovalNodeList = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/wxList`,
    data,
    isLoading: true
  })

/**
 * 地址信息
 */
export const getApprovalAllAddrInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalAllAddrInfo`,
    data,
    isLoading: true
  })

// 收货地址变更记录
export const getApprovalAddrInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalAddrInfo`,
    data,
    isLoading: true
  })

/**
 * 资质信息
 */
export const getApprovalAllCertInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalAllCertInfo`,
    data,
    isLoading: true
  })

/**
 * 基本信息
 */
export const getApprovalCustInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalCustInfo`,
    data,
    isLoading: true
  })

/**
 * 开票信息
 */
export const getApprovalAllInvoiceInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalAllInvoiceInfo`,
    data,
    isLoading: true
  })
/**
 * 委托人信息
 */
export const getApprovalUserInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalUserInfo`,
    data,
    isLoading: true
  })

/**
 * 委托资质信息
 */
export const getApprovalAllUserCertInfo = data =>
  get({
    url: `${api.baseUrl}/customer/approvalnodeconfig/approvalAllUserCertInfo`,
    data,
    isLoading: true
  })

/**
 * 我的企业审核不通过的信息
 */
export const getMyRefuseCustomerInfo = data =>
  get({
    url: `${api.baseUrl}/cust/custuser/getMyRefuseCustomerInfo`,
    data,
    isLoading: true
  })
/**
 * 提交我的企业审核不通过的信息
 */
export const updateRefuseCustomerInfo = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/updateAllCust`,
    data: { ...data, source: 'user', requestSourceFrom: 'PC' },
    isLoading: true
  })

/**
 * pc/小程序补充资质新增接口（新增）
 */
export const addMustCusts = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/addMustCusts`,
    data: { ...data, source: 'user' },
    isLoading: true
  })

/**
 * pc/小程序补充资质今日不再提醒接口
 */
export const cancelWarnCertState = data =>
  post({
    url: `${api.baseUrl}/cust/custuser/cancelWarnCertState`,
    data,
    isLoading: true
  })

/**
 * 客户端删除非必要资质接口
 */
export const deleteCertAPI = data =>
  post({
    url: `${api.baseUrl}/customer/approvalcust/deleteCert`,
    data: { ...data, source: 'user' },
    isLoading: true
  })
