<template>
  <div class="shop-all">
    <div class="search-bar">
      <SearchBar style="width: 1200px;margin: 0 auto; " :licence="true" />
    </div>
    <div style="background: #fff;">
      <allProductClass selected-type="allOrgs" />
    </div>

    <ContentTitle v-if="list.length" title="全部店铺" style="margin: 0 auto;width:1200px; padding: 16px;" />
    <div v-if="list.length" class="list">
      <div v-for="item in list" :key="item.orgId" class="item">
        <div class="shop-info">
          <div style="display: flex;">
            <img
              class="left"
              width="48"
              height="48"
              :src="item.orgLogo ? getFilePath('PS_01', item.orgLogo) : defaultLogo"
              alt=""
              @click="goBusiness(item)"
            >
            <p class="org-abbr line-clamp-2" @click="goBusiness(item)">{{ item.orgAbbr }}</p>
          </div>
          <div class="middle line-clamp-1">
            <span class="">上架商品 <span class="productNum">{{ item.productNum }}</span> 种｜</span>
            <span class="info">{{ item.deliveryInfo }}</span>
          </div>
          <div class="btns">
            <template> <!-- orgSort 1是建采 2是未建采 -->
              <div v-if="isSaved.indexOf(item.orgId) == -1" class="follow-container" @click="saveShopMethod(item.orgId)">
                <svg-icon style="" class="follow" name="follow" />
              </div>
              <Tooltip content="取消关注" placement="bottom" style="height: 30px">
                <img
                  v-if="isSaved.indexOf(item.orgId) > -1"
                  class="saved_img"
                  src="@/assets/images/home/alreadySave.png"
                  alt=""
                  @click="deleteSaved(item.orgId)"
                >
              </Tooltip>
            </template>
            <div class="contact" @click="handleContact(item)">
              <img width="16px" height="16px" src="@/assets/images/shopHome/phone-call.png" alt="">
              <span class="contact-org">联系商家</span>
            </div>
          </div>
          <div class="right">
            <Button class="go-shop" style="" @click="goBusiness(item)">进店看看<svg-icon
              name="arrow-right"
              class-name="icon"
            /></Button>
          </div>
        </div>
        <template v-if="item.prdProductEsVo?.length > 0">
          <div
            v-for="(item2, index2) in item.prdProductEsVo.slice(0, 4)"
            :key="index2"
            class="product"
            @click="toProductDetail(item2)"
          >
            <g-img
              class="img"
              :src="getFilePath('PS_01', item2.fileId) || defaultPath"
              :default-path="defaultPath"
              :lazy="true"
            />
            <!-- <p
              :style="{visibility: item2.marketing?.activityPrice || item2?.customerTypePrice?.[0]?.price !== undefined ? 'visible' : 'hidden'}"
              class="price">
              ¥{{item2.marketing?.activityPrice || item2?.customerTypePrice?.[0]?.price}}
            </p> -->
            <PriceArea class="price" :goods-item="item2" />
            <p class="name line-clamp-2">{{ item2.generalChineseName }}</p>
          </div>
          <div v-for="count in 4 - item.prdProductEsVo?.length" class="product product-preparing-good">
            <img class="preparing-good" src="@/assets/product/preparing-good.png">
          </div>
        </template>
        <template v-else>
          <div v-for="count in 4" class="product product-preparing-good">
            <img class="preparing-good" src="@/assets/product/preparing-good.png">
          </div>
        </template>
      </div>
    </div>
    <div v-if="list.length === 0" class="lock_record">
      <img src="@/assets/images/search/no-data.png" alt="">
    </div>
    <Pagination
      v-if="list.length"
      :show-page-num="true"
      :total="total"
      :size="pageSize"
      :num="pageNum"
      style="width: 1200px;margin: 0 auto;"
      @change="handleChange"
    />
    <ShopContact :visible.sync="isShowRelationShoopModal" :shop-data="shopData" />
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue'
import allProductClass from './coms/allProductClass.vue' // 全部商品分类
import CmsDialogMixin from '@/common/mixins/cmsDialog'
import { getFilePath } from '@/common/utils/base'
import defaultLogo from '@/assets/images/shopHome/default-logo.png'
import { searchAllBusiness } from '@/common/service'
import ContentTitle from '@/components/ContentTitle'
import defaultPath from '@/assets/images/common/good_default.png'
import GImg from '@/components/GImg'
import { transformEsData } from '@/common/utils'
import { detaiHandler } from './index'
import PriceArea from '@/components/priceArea'
import ShopContact from '@/components/ShopContact.vue'
import {
  addSaveShop,
  queryShopSave,
  deleteShopSaved,
  getOrgCollect
} from '@/common/service/shopHome.js'

export default {
  name: 'ShopAll',
  components: {
    SearchBar,
    allProductClass,
    ContentTitle,
    GImg,
    PriceArea,
    ShopContact
  },
  data() {
    return {
      getFilePath,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      isSaved: [],
      isShowRelationShoopModal: false,
      modalPhone: '',
      defaultLogo,
      defaultPath,
      shopData: {}
    }
  },
  computed: {

  },
  watch: {
  },
  mounted() {
  },
  created() {
    this.getAllBusiness()
  },
  activated() {
  },
  methods: {
    async handleCopy(text) {
      try {
        await copy(text)
      } catch (error) {
        console.error('复制到剪贴板失败:', error)
      }
    },
    async getAllBusiness() {
      // {"pageNum":1,"pageSize":10,"condition":{"orgName":"清肺"}}
      try {
        console.info('final ~ file:index method:getAllBusiness line:46 -----', this.keyword)
        const res = await searchAllBusiness({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          condition: {
          }
        })
        console.log(res, 'sdfsf')
        if (res?.code === 200) {
          const { total, records } = res.data
          this.list = records
          this.total = total
          this.orgCollect(records.map(item => item.orgId))
        }
      } catch (e) { console.log(e) }
    },
    handleChange(o) {
      const { pageNumber, pageSize } = o
      this.pageNum = pageNumber
      this.pageSize = pageSize
      this.getAllBusiness()
    },
    goBusiness(item) {
      this.$router.push({
        path: `/shopHome?orgId=${item.orgId}`
      })
      this.$zhuge.track('店铺-店铺页点击店铺', {
        '店铺名称': item.orgAbbr,
        '店铺排序': this.list.findIndex(d => d.orgId === item.orgId) + 1
      })
    },
    async saveShopMethod(orgId) {
      const obj = {
        'customerId': '',
        'orgId': orgId,
        'userId': ''
      }
      const data = await addSaveShop(obj)
      if (data.code === 200) {
        this.$message.info(data.message)
        this.getShopStatus(orgId)
      }
    },
    async deleteSaved(orgId) {
      await deleteShopSaved([orgId])
      this.$message.info('取消关注成功')
      await this.getShopStatus(orgId)
    },
    async getShopStatus(orgId) {
      const peram = {
        'ascs': [
        ],
        'condition': {
          'customerId': '',
          'orgId': orgId,
          'userId': ''
        },
        'descs': [],
        'pageNum': 1,
        'pageSize': 10
      }
      const { data } = await queryShopSave(peram)
      if (data?.records?.length > 0) {
        this.isSaved.push(orgId)
      } else {
        const index = this.isSaved.indexOf(orgId)
        if (index > -1) {
          this.isSaved.splice(index, 1)
        }
      }
    },
    async orgCollect(orgIds) {
      if (orgIds) {
        const { data } = await getOrgCollect({ orgIds })
        data?.forEach(item => {
          if (item.orgFollow) {
            this.isSaved.push(item.orgId)
          }
        })
      }
    },
    toProductDetail(data) {
      transformEsData(data)
      detaiHandler(data, '1')
    },
    handleContact(item) {
      this.isShowRelationShoopModal = true
      this.shopData = {
        salerPhone: item.salerphone,
        salerName: item.salername,
        servicePhone: item.servicePhone
      }
    }
  }
}
</script>
<style scoped lang="less">
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;

  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}

.lock_record {
  width: 216px;
  height: 238px;
  margin: 10% auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.list {
  width: 1200px;
  margin: 0 auto;

  .item {
    background: #FFF;
    border-radius: 8px;
    margin-bottom: 16px;
    height: 225px;
    display: flex;

    .shop-info {
      padding: 16px;
      box-sizing: border-box;
      width: 288px;
      height: 100%;

      .left {
        cursor: pointer;
      }

      .org-abbr {
        color: rgba(0, 0, 0, 0.90);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-left: 8px;
        cursor: pointer;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .middle {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
      }
    }

    .saved_img {
      width: 75px !important;
      margin-right: 8px;
    }

    .follow-container {
      margin-right: 8px;
      height: 30px;

      :hover {
        background-color: #E7E7E7;
        border-radius: 20px;
      }
    }

    .follow {
      width: 64px;
      height: 30px;
      fill: none;
    }

    .btns {
      display: flex;
      height: 30px;
      margin: 8px 0 12px 0;

      .contact {
        border-radius: 24px;
        border: 0.5px solid #DCDCDC;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .contact-org {
          margin-left: 6px;
          color: #3853E2;
        }
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }

    .go-shop {
      border-color: #DCDCDC;
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
    }
  }

  .product {
    width: 228px;
    border-left: 0.5px solid #E7E7E7;
    padding: 12px 16px 16px;
    box-sizing: border-box;

    .img {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }

    .price {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      color: #CB272D;
      margin: 10px 0 2px;
    }

    .name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .product-preparing-good {
    display: flex;
    justify-content: center;

    .preparing-good {
      height: 120px;
      height: 120px;
      margin-top: 40px;
    }
  }
}
</style>
