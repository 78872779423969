<template>
  <!-- 头部搜索栏 -->
  <div class="searchBar">
    <div class="searchBar-content">
      <div class="left">
        <img src="@/assets/images/shopHome/logo.png" @click="goHome" />
      </div>
      <div class="middle" :style="{ 'margin-left': isShowBtn ? '342px' : '0' }" v-if="!isHiddenSearch">
        <div class="input-search">
          <Input
            v-model="searchKey"
            size="large"
            search
            :enter-button="config.searchText"
            :placeholder="noticeBarPlaceholder || (showNoticeBar && ' ') || keyword || '请输入药品/厂商/包装规格'"
            @on-focus="inputFocusHandler"
            @on-blur="focurse = false"
            @input="getKeywordList(_this)"
            @on-search="goSearch" />
          <span v-if="!focurse && searchKey && typeof searchKey == 'string' && searchKey != '请输入商品名称' && searchKey != '请输入内容'" class="serach_key">
            {{ searchKey }}
            <Icon type="md-close" @click="clearSearchKey" />
          </span>
          <!-- 在配置了关键字时滚动显示 -->
          <NoticeBar class="notice-bar" :value="keyword" @change="noticeBarValue = $event" v-if="showNoticeBar"></NoticeBar>
          <div class="search-relate" :style="{ width: shop ? '500px' : '517px' }" v-if="showKeywordList">
            <div v-if="!shop" class="shop-item" v-for="(item, index) in shopList.slice(0, 5)" :key="index" @click.stop="goToShopHome(item)">
              <svg-icon name="shop-icon" />
              <span v-html="item.name"></span>
              <Icon style="color: rgba(0, 0, 0, 0.6)" type="ios-arrow-forward" />
            </div>
            <span v-if="!shop && shopList.length > 5" class="green" @click.stop="handleShopAll">
              <Icon type="ios-search" size="16" color="rgba(0, 0, 0, 0.6)" />
              搜索全部“
              <span>{{ searchKey }}</span>
              ”商家
              <Icon style="color: rgba(0, 0, 0, 0.6)" type="ios-arrow-forward" />
            </span>
            <span v-for="(item, index) in keywordList" :key="item.suggest" v-html="item" @click.stop="handleSuggestList(index)"></span>
          </div>
          <div class="search-relate" :style="{ width: shop ? '500px' : '517px' }" v-else-if="showHistoryKeywordList">
            <p class="history-title">
              <span>历史搜索</span>
              <span class="clear_btn" @click="clearHistory">清空搜索历史</span>
            </p>
            <div class="history_item" v-for="(history, index) in getRecord" :key="index" @click.stop="handleHistoryRecord(history)">
              <p style="display: flex; align-items: center">
                <img style="width: 16px; height: 16px" v-show="history.keywordsType == 'org'" :src="shop_icon" alt="" />
                {{ history.keywordsName }}
              </p>
              <Icon class="close" style="font-size: 18px" type="md-close" @click.stop="delHistoryKey(history.id)" />
            </div>
          </div>
        </div>
        <Button v-if="config.searchAllBtn" class="search-all" size="large" type="warning" @click="goSearchAll">搜全场</Button>
        <div v-if="belowKeyword" class="below-keyword">
          <div v-for="(item, index) in belowKeyword" class="keyword-block">
            <div @click="belowKeywordHandler(item)" class="keyword">{{ item.type == 1 ? item.keywords : item.contentKeywords }}</div>
            <div v-if="index != belowKeyword.length-1" class="line"></div>
          </div>
        </div>
      </div>
      <div class="right" v-if="(isShowShoopCart || licence) && !isHiddenSearch">
        <!-- <LicenceBtn v-if="licence" class="licence-btn" /> -->
        <CartBtn v-if="isShowShoopCart" class="card-btn" @queryCart="queryCart"  />
        <!-- 常购清单 -->
        <div class="search-buy-btn" @click="goBuyOrder" @mouseover="showBuyOrder = true" @mouseleave="showBuyOrder = false">
          <img v-if="showBuyOrder" class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/often-buy-list-active.png">
          <img v-else class="search-buy-icon" width="16px" height="16px" src="@/assets/images/search/often-buy-list.png">
          <span class="search-buy-text">常购清单</span>
        </div>
        <!-- 消息 -->
        <MessageBtn></MessageBtn>
      </div>
    </div>
  </div>
</template>

<script>
import CartBtn from '@/components/CartBtn.vue'
import MessageBtn from '@/components/MessageBtn.vue'
import LicenceBtn from '@/components/LicenceBtn'
import NoticeBar from '@/components/NoticeBar'
import { getHistorySearch, clearHistory, addHistorySearch, deleteHistorySearch, listProductOrgNameByEs } from '@/common/service'

import shop_icon from '@/assets/images/search/shop_icon.png'
import { mapActions, mapMutations, mapState } from 'vuex'
import { cmsLink } from '@/views/home/index'
import { mapGetters } from 'vuex'
import {
  getStorageSync
} from '@/common/utils/storage'
function debounce(fn, delay = 500) {
  let timer
  return function () {
    let context = this,
      args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}
export default {
  name: 'SearchBar',
  props: {
    shop: {
      // 是否是商家，支持两种样式，商家和平台
      type: Boolean,
      default: false
    },
    // 商家列表页
    business: {
      type: Boolean,
      default: false
    },
    licence: {
      // 是否显示资质按钮
      type: Boolean,
      default: false
    },
    // 是否显示购物车按钮，默认显示
    isShowShoopCart: {
      type: Boolean,
      default: true
    },
    keyword: '',
    noticeBarPlaceholder: '', // 点击noticebar选择的placeholder
    isHiddenSearch: {
      type: Boolean,
      default: false
    },
    belowKeyword: [],
  },
  computed: {
    ...mapState({
      getKeyword: (state) => state.product.keyword,
      searchStatus: (state) => state.product.searchStatus,
      totalMsgNum: (state) => state.message.totalMsgNum
    }),
    config() {
      let data = {
        searchAllBtn: this.shop,
        searchText: this.shop ? '搜本店' : '搜索'
      }
      return data
    },
    // 都为fasle 时按钮隐藏，搜索栏靠右
    isShowBtn() {
      return !this.shop && !this.licence && !this.isShowShoopCart
    },
    // 取历史记录的前十条
    getRecord() {
      return this.historyRecord.slice(0, 10)
    },
    _this() {
      return this
    },
    // 首页搜索框配置的有多个关键词显示关键词bar
    showNoticeBar() {
      // noticeBarPlaceholder 是点击了noticebar，就隐藏noticebar
      return !this.noticeBarPlaceholder && this.keyword && Array.isArray(this.keyword) && this.keyword?.length > 1
    }
  },
  watch: {
    keywordList: {
      handler(val) {
        if (val) {
          document.body.addEventListener('click', (e) => {
            this.showKeywordList = false
          })
        } else {
          document.body.removeEventListener('click', () => {})
        }
      },
      deep: true
    },
    showHistoryKeywordList(val) {
      if (val) {
        document.body.addEventListener('click', (e) => {
          if (e.target.className != 'ivu-input ivu-input-large') {
            this.showHistoryKeywordList = false
          }
        })
      } else {
        document.body.removeEventListener('click', () => {})
      }
    },
    getKeyword: {
      handler(val) {
        // if(this.$route.path != '/shopHome'){
        // this.searchKey = val
        // }else{
        this.searchKey = val
        // }
      },
      immediate: true
    }
  },
  components: {
    CartBtn,
    LicenceBtn,
    NoticeBar,
    MessageBtn
  },
  data() {
    return {
      shop_icon,
      searchKey: '',
      showKeywordList: false,
      showHistoryKeywordList: false,
      copyKeywordList: [], // 显示原推荐名
      keywordList: [], // 关键词列表
      historyRecord: [], // 历史记录
      getHistoryRecordType: [], // 历史记录类型
      noticeBarValue: '',
      focurse: false,
      shopList: [], //商家列表
      showBuyOrder: false,
      keywordRecord:[]
    }
  },
  mounted() {
    const token = getStorageSync('accessToken')
    if (token) {
      this.$store.dispatch('message/updateMessageNum')
    }
    if (this.$route.query.keyword) {
      if (this.$route.path != '/shopHome') {
        this.$nextTick(() => {
          this.searchKey = this.$route.query.keyword
        })
      } else {
        this.searchKey = ''
      }
    }
    if (this.$route.path != '/search/list') {
      this.setKeyword('')
    }
    //  获取历史记录
    this.getHistoryRecord()
    this.setKeywordRecord(null)
  },
  methods: {
    ...mapMutations({
      setOrgId: 'product/SET_ORG_ID',
      setKeyword: 'product/SET_KEYWORD',
      setSearchStatus: 'product/SET_SEARCH_STATUS',
      setKeywordRecord: 'product/SET_KEYWORD_RECORD',

      setHasSelect: 'product/SET_HAS_SELECT',
      setSelectBusiness: 'product/SET_SELECT_BUSINESS',
      setSelectFactory: 'product/SET_SELECT_FACTORY',
      setSelectWrap: 'product/SET_SELECT_WRAP',
      setSearchType: 'product/SET_SEARCHTYPE_STATE',
    }),
    ...mapActions({
      getSuggestList: 'product/getSuggestList',
      getSearchSuggest:'product/getSearchSuggest', 
    }),
    // 清空搜索历史
    async clearHistory() {
      await clearHistory()
      this.historyRecord = []
    },
    clearSearchKey() {
      this.setKeyword('')
      this.searchKey = ''
      this.keyword = ''
      this.setKeywordRecord(null)
    },
    // 返回首页
    goHome() {
      this.setKeyword('')
      if (this.$route.path !== '/') this.$router.push('/')
    },
    // 跳转商家首页
    goToShopHome(item) {
      this.$router.push({
        path: `/shopHome?orgId=${item.orgId}`
      })
    },
    // 搜索商家
    async searchShopList() {
      this.setKeywordRecord(null)
      if (this.searchKey?.length < 2) {
        this.shopList = []
        return
      }
      try {
        const { data } = await listProductOrgNameByEs({
          orgName: this.searchKey,
          page: {
            pageSize: 6,
            pageNumber: 1
          }
        })
        if (data?.length) {
          this.shopList =
            data?.map((item) => {
              const name = item.orgAbbr?.replaceAll(this.searchKey, `<a style="color: #CE7720; display: inline-block;">${this.searchKey}</a>`)
              return {
                name: `${name}`,
                orgId: item.orgId
              }
            }) || []
        } else {
          this.shopList = []
        }
        if (this.searchKey == '') {
          this.showKeywordList = false
          this.showHistoryKeywordList = true
        } else {
          this.showKeywordList = true
          this.showHistoryKeywordList = false
        }
      } catch (error) {
        this.$message.error(error?.data?.message)
      }
    },
    // 搜索建议列表
    async searchSuggestList() {
      /** 2024.12.3号，搜索关键词联词调整，最新逻辑暂时品牌、商品名、通用名拼接，调用新的联词接口
        // const res = await this.getSuggestList({
        //   suggestWord: {
        //   keywords: this.searchKey
        //  }
        // })
      */
      const res = await this.getSearchSuggest({
        dropDownKeyword: this.searchKey
      })
      this.copyKeywordList = []
      this.keywordRecord = res?.records
      this.keywordList =
        res?.records.map((item) => {
        /** 2024.12.3号，搜索关键词联词调整，最新逻辑暂时品牌、商品名、通用名拼接
          // this.copyKeywordList.push(item.suggest)
          // let html = item.suggest.replaceAll(this.searchKey, `<a style="color: #CE7720; display: inline-block;">${this.searchKey}</a>`)
          // this.copyKeywordList.push(item.suggest)
        */
        const searchResStr = `${item.brandName ? item.brandName + ' ' : ''}${item.productName? item.productName + ' ' : ''}${item.generalChineseName || ''}`
        this.copyKeywordList.push(searchResStr)
          let html = searchResStr.replaceAll(this.searchKey, `<a style="color: #CE7720; display: inline-block;">${this.searchKey}</a>`)
          return `${html}`
        }) || []
      if (this.searchKey == '') {
        this.showKeywordList = false
        this.showHistoryKeywordList = true
      } else {
        if (this.keywordList.length > 0) {
          this.showKeywordList = true
        } else {
          this.showKeywordList = false
        }    
        this.showHistoryKeywordList = false
      }
    },

    // 获取关联词列表
    getKeywordList: debounce((_this) => {
      _this.searchSuggestList()
      _this.searchShopList()
    }, 500),
    clearCondition() {
      // 搜索时需要清空筛选条件
      this.setSelectBusiness([])
      this.setSelectFactory([])
      this.setSelectWrap([])
      this.setHasSelect([])
    },
    // 点击搜索或按下回车键时触发
    async goSearch(val) {
      this.clearCondition()
      this.showKeywordList = false
      // 按关键词搜索
      if (this.noticeBarValue) {
        val = this.noticeBarValue
      }
      if (this.shop) {
        this.$zhuge.track('搜索-点击搜索框', {
          '搜索框页面名称' : '店铺首页',
        })
        // 商家搜本店
        this.$emit('changeTabs', { type: 'all_goods', isAllGoods: true })
      } else {
        this.$zhuge.track('搜索-点击搜索框', {
          '搜索框页面名称' : '首页',
        })
      }
      //  this.setSearchStatus(false) 区别商家搜索
      // this.setSearchStatus(false)
      const value = val.replace(/\s/g, '')
      if (val) {
        await this.setHistoryRecord({ keywordsType: 'prd', keywordsName: value })
      }
      this.setKeyword(val)
      if (!this.shop && !this.business && this.$route.path !== '/search/list') {
        await this.$router.push('/search/list')
      }
      if (!this.shop) await this.$emit('getData', 'search')
      this.setSearchStatus(!this.searchStatus)
    },
    // 搜全场
    goSearchAll() {
      // 商家页点击搜全场，需要去掉orgId
      this.setOrgId(null)
      this.goSearch(this.searchKey)
      this.setKeyword(this.searchKey)
      window.open('/search/list')
      // this.$router.push({
      //   path: '/search/list',
      //   query: {
      //     keyword: this.searchKey
      //   }
      // })
    },
    // 获取历史记录
    async getHistoryRecord() {
      const res = await getHistorySearch()
      this.historyRecord = res.data
    },
    // 设置历史记录
    async setHistoryRecord(val) {
      const res = await addHistorySearch(val)
      if (res.code == 200) {
        this.getHistoryRecord()
      }
    },
    async delHistoryKey(id) {
      const res = await deleteHistorySearch({ id })
      if (res.code == 200) {
        this.getHistoryRecord()
      }
    },
    // 点击历史记录搜索
    handleHistoryRecord(val) {
      this.clearCondition()
      this.showHistoryKeywordList = false
      this.searchKey = val.keywordsName
      this.setKeyword(val.keywordsName)
      this.setSearchType('历史搜索')
      if (val.keywordsType == 'org') {
        this.handleShopAll()
      } else if (this.shop) {
        this.setSearchStatus(!this.searchStatus) // 使用原有逻辑可以更新数据
        this.$emit('changeTabs', { type: 'all_goods', isAllGoods: true })
      } else if (this.$route.path !== '/search/list' && !this.business) {
        this.$router.push('/search/list')
      }
      this.$emit('getData', 'search')
    },
    // 点击推荐关联词搜索
    handleSuggestList(index) {
      this.setKeywordRecord(null)
      this.showKeywordList = false
      const val = this.copyKeywordList[index]
      const valRecord = this.keywordRecord[index]
      this.setKeywordRecord(valRecord || null)
      this.setHistoryRecord({ keywordsType: 'prd', keywordsName: val })
      this.searchKey = val
      this.setKeyword(val)
      this.setSearchType('关键词搜索')
      if (this.shop) {
        // 商家搜本店
        this.setSearchStatus(!this.searchStatus) // 使用原有逻辑可以更新数据
        this.$emit('changeTabs', { type: 'all_goods', isAllGoods: true })
      } else if (this.$route.path !== '/search/list' && !this.business) {
        this.$router.push('/search/list')
        this.$emit('getData')
      }
      // this.copyKeywordList = []
      this.$emit('getData', 'search')
    },
    // 点击推荐商家去商家列表
    handleShopAll() {
      this.setHistoryRecord({ keywordsType: 'org', keywordsName: this.searchKey })
      this.showKeywordList = false
      this.$router.push({
        path: '/shop/list',
        query: {
          keyword: this.searchKey
        }
      })
      this.$emit('getData')
    },
    inputFocusHandler() {
      this.focurse = true
      this.getKeywordList(this)
      if (this.showNoticeBar) {
        this.noticeBarPlaceholder = this.noticeBarValue
        setTimeout(() => {
          this.noticeBarValue = ''
        }, 500)
      }
    },
    belowKeywordHandler(item) {
      if (item.type == 1) {
        this.setKeyword(item.keywords)
        this.$router.push('/search/list?searchType=热门搜索')
      } else if (item.type == 2) {
        cmsLink.bind(this)(item.link)
      }
    },
    queryCart() {
      this.$emit("queryCart")
    },
    goBuyOrder() {
      if (this.$route.path !== '/center/purchaseList') this.$router.push('/center/purchaseList')
    },
    goMessage() {
      if (this.$route.path !== '/msg/index') this.$router.push('/msg/index')
    },
  }
}
</script>

<style lang="less" scoped>
.searchBar {
  width: 100%;
  height: 85px;
  .shop-item {
    display: flex !important;
    align-items: center !important;
    padding: 0 4px !important;
    span {
      padding: 6px 0 6px 3px !important;
    }
  }
  // background-color: #FFF;
  .searchBar-content {
    padding-top: 16px;
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    .left,
    .middle,
    .right {
      display: inline-block;
    }
    .left {
      // width: 206px;
      width: 195px;
      height: 48px;
      display: inline-block;
      margin-right: 73px;
      cursor: pointer;
      img {
        width: 195px;
        height: 48px;
      }
    }
    .middle {
      display: flex;
      position: relative;
      .input-search {
        position: relative;
        display: inline-block;
        width: 550px;
        .serach_key {
          letter-spacing: 1px;
          position: absolute;
          top: 7px;
          left: 10px;
          z-index: 9;
          border-radius: 4px;
          border: 1px solid #dcdcdc;
          background: #f5f5f5;
          color: #4b4b4b;
          font-size: 12px;
          line-height: 20px;
          padding: 2px 4px;
        }
        .shop-list {
          margin: 7px 0 0 -6px;
          height: 22px;
          overflow: hidden;
          span {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            padding: 0 6px;
            cursor: pointer;
            &:hover {
              color: #ce7720;
            }
          }
        }
        ::v-deep .ivu-input-group-append {
          border-radius: 8px;
        }
        ::v-deep .ivu-input {
          border: 2px solid #f99d33 !important;
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        ::v-deep .ivu-input:focus {
          box-shadow: none !important;
        }
        .search-relate {
          margin-top: 5px;
          position: absolute;
          width: 500px;
          min-height: 0px;
          max-height: 60vh;
          display: flex;
          flex-direction: column;
          // background: #fff;
          // border: 1px solid #f5f5f5;
          border-radius: 8px;
          z-index: 10;
          padding: 8px 8px;
          border-radius: 8px;
          background: #fff;
          overflow-y: hidden;
          /* shadow/3 */
          box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
          &:hover {
            overflow-y: auto;
          }

          div,
          span {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            padding: 6px 8px;
            cursor: pointer;
            border-radius: 4px;
            &:hover {
              background-color: #f5f5f5;
            }
          }
          .history_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              margin-right: 2px;
            }
            .close {
              display: none;
              color: #a6a6a6;
            }
            &:hover {
              .close {
                display: block;
                &:hover {
                  color: #4b4b4b;
                }
              }
            }
          }
          .history-title {
            display: flex;
            font-size: 12px;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.6);
            .clear_btn {
              color: #3853e2;
            }
            span {
              &:hover {
                background: none;
              }
            }
          }
          .green {
            color: #4b4b4b;
            padding: 4px;
            span {
              color: #ce7720;
              padding: 0;
            }
            .svg-icon {
              margin-right: 5px;
            }
          }
        }
      }
      .search-all {
        margin-left: 16px;
        border-radius: 8px;
      }
      ::v-deep.ivu-input-search {
        background-color: #f99d33 !important;
      }
      ::v-deep.ivu-input {
        border: 2px solid #f99d33 !important;
      }
    }
    .notice-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 494px;
      height: 40px;
      pointer-events: none;
      z-index: 2;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .card-btn {
        margin-left: 16px;
      }
      .licence-btn {
        margin-left: 16px;
      }
    }
  }
}
::v-deep .ivu-input-group-large .ivu-input {
  padding-left: 8px !important;
}
::v-deep .ivu-input-group-large .ivu-input {
  font-size: 14px;
}
.below-keyword {
  position: absolute;
  top: 48px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  .keyword-block {
    display: flex;
    align-items: center;
    &:first-child .keyword {
      padding: 0 8px 0 0 !important;
    }
  }
  .keyword {
    padding: 0 8px;
    cursor: pointer;  
  }
  .line {
    width: 1px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.25);
  }
  display: flex;
}

.search-buy-btn {
  width: 108px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEE;
  margin-left: 12px;
  .search-buy-icon {
    width: 16px;
    height: 16px;
  }
  .search-buy-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    margin-left: 8px;
  }

  &:hover {
    border: 1px solid rgba(250, 179, 87, 1);
    .search-buy-text {
      color: rgba(230, 125, 0, 1);
    }
  }
}

.cart-num {
  position: absolute;
}

.cart-num {
  width: 28px;
  height: 15px;
  line-height: 15px;
  top: -10px;
  right: 6px;
  text-align: center;
  background-size: 100% 100%;

  .num {
      font-size: 12px;
      color: #ffffff;
  }

  &.indivdual {
      width: 16px;
      height: 16px;
      background-image: url(../assets/images/common/car-num-indivdual.png);
  }

  &.ten {
      background-image: url(../assets/images/common/car-num-ten.png);
      width: 24px;
      height: 16px;
  }

  &.hundred {
      width: 32px;
      height: 16px;
      background-image: url(../assets/images/common/car-num-hundred.png);
  }
}
</style>
