<template>
  <div id="app">
    <TopMenu v-if="header_show" ref="topMenu" />
    <div :class="loginPage ? 'loginPage' : 'main-content'" :style="mainContentStyle">
      <keep-alive :include="include">
        <router-view class="content" @header="header" @footer="footer" />
      </keep-alive>
    </div>
    <Footer v-if="footer_show" ref="footer" @loadData="footerLoadData" />
    <CmsModal />
  </div>
</template>
<script>
import TopMenu from '@/components/TopMenu'
import Footer from '@/components/Footer'
import RouterContanst from '@/router/router-contanst'
import CmsModal from '@/components/c-cms-modal/index.vue'
import intersectionObserver from '@/common/utils/intersectionObserver'
import { getDeviceInfo } from '@/utils/index'
import {
  getStorageSync
} from '@/common/utils/storage'
import { asyncQueryCart } from '@/common/service'
import { getCartFieldData } from '@/common/utils'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    TopMenu,
    Footer,
    CmsModal
  },
  provide() {
    return {
      topApp: this,
      intersectionObserver: new intersectionObserver(),
      getCustomerInfo: () => {
        this.$refs.topMenu?.obtainCustomerList()
      }
    }
  },
  errorCaptured(error){
    this.$zhuge.track('发生代码错误', {
      '错误信息': error,
    })
  },
  data() {
    return {
      header_show: false,
      footer_show: false,
      include: [],
      mainContentStyle: {},
      showAttachCompanyInfo: false,
      loginPage: false,
      countdown: null,
      storageKey: 'storageTime',
      storageTime: null, // 开始时间
      formattedTime: '',
      targetDateInSeconds: null
    }
  },
  computed: {
    ...mapState({
      selectedProductList: (state) => state.car.selectedProductList
    })
  },
  watch: {
    footer_show() {
      this.setMainContentHeight('all')
    },
    $route(to, from) {
      const bgList = [
        '/login',
        '/ProductEntry',
        '/ProductEntryForm'
      ]
      this.loginPage = bgList.includes(to.path)
    }
  },
  beforeCreate() {
    this.$router.beforeEach((to, from, next) => {
      this.setTitle(to)
      this.setKeepAlive(from)
      this.setTopFooterStatus(to)
      this.setFooterStatus(to)
      this.setShowAttachInfo(to)
      this.noTokenToLogin(to.name)
      window.scrollTo(0, 0)
      next()
    })
    this.$router.afterEach((to, from, next) => {
      this.setTitle(to)
      this.noTokenToLogin(to.name)
    })
  },
  created() {
    // 判断本地是否存储了登录信息
    const loginInfo = JSON.parse(localStorage.getItem('loginData') || '{}')?.value
    if (loginInfo && loginInfo.nick_name) {
        if (loginInfo.nick_name) {
          this.$zhuge.track('打开润药商城', {
            '所在平台' : 'PC',
            '客户名称' : loginInfo.nick_name,
          })
          this.$zhuge.identify(loginInfo.user_id, {
            '手机号': loginInfo.nick_name,
            '渠道': "PC"
          })
        }
      }else{
        this.$zhuge.track('打开润药商城', {
          '所在平台' : 'PC',
          '客户名称' : "未登录",
        })
    }
    this.setTitle(this.$route)
    this.setKeepAlive(this.$route)
    this.setShowAttachInfo(this.$route)
    this.noTokenToLogin(this.$route.name)
    this.$bus.$off('setFooterBar')
    this.$bus.$on('setFooterBar', (bool) => {
      this.footer_show = bool
    })
    // this.loadStorageTime()
    const token = getStorageSync('accessToken')
    if (token) {
      console.log("xxx", this.$route)
      if (!['carPage', 'OrderConfirm'].includes(this.$route?.name) && this.$route?.name) {
        this.setAsyncQueryCart()
      }
    }

    getDeviceInfo()
  },
  mounted() {
    this.setTopFooterStatus(this.$route)
    this.setFooterStatus(this.$route)
    this.countdown = setInterval(() => {
      if (new Date().getTime() - Number(localStorage.getItem('countdownTime')) > 4 * 60 * 1000) {
        this.setAsyncQueryCart()
        localStorage.setItem('countdownTime', new Date().getTime() + '')
      }
    }, 4 * 60 * 1000)
 
    
  },
  activated() {
    console.log('监听激活页面')
    this.loadStorageTime()
  },
  beforeDestroy() {
    this.countdown && clearInterval(this.countdown)
  },
  destroyed() {
    this.countdown && clearInterval(this.countdown)
  },
  methods: {
    // 是否显示头部
    header: function(bool) {
      this.header_show = bool
    },
    // 是否显示底部
    footer: function(bool) {
      this.footer_show = bool
    },
    setKeepAlive(router) {
      if (router?.meta?.keepAlive && this.include.indexOf(router.name) == -1) { this.include.push(router.name) }
    },
    setTopFooterStatus(route) {
      // 配置 不需要 TopMenu Footer 组件名单
      const noHeader = [
        RouterContanst.USER.LOGIN, // 登陆页
        RouterContanst.USER.REGIST,
        RouterContanst.USER.FORGET_PWD,
        'publicHelperCenter',
        'InitialQualification',
        'DrugReport',
        'ProductEntryForm',
        RouterContanst.USER.ANNOUNCE_FILE
      ]
      if (noHeader.indexOf(route.name) > -1) {
        this.header_show = false
      } else {
        this.header_show = true
      }
    },
    setFooterStatus(route) {
      // 配置 不需要 TopMenu Footer 组件名单
      const noHeader = [
        'carPage',
        'OrderConfirm',
        'publicHelperCenter',
        'addGift',
        'InitialQualification',
        'DrugReport',
        'SpeedOrder',
        'PurchasePlan',
        'Home'
      ]
      if (noHeader.indexOf(route.name) > -1) {
        this.footer_show = false
      } else {
        this.footer_show = true
      }
    },
    setShowAttachInfo(route) {
      // 配置 不需要 TopMenu Footer 组件名单
      const ATTACH_INFO_PAGES = [
        'Home', // 登陆页
        RouterContanst.HOME.HOME_PAGE, // 登陆页
        'OrderList',
        'CenterInfo',
        'carPage',
        'CenterMerchant'
      ]
      if (ATTACH_INFO_PAGES.indexOf(route.name) > -1) {
        this.showAttachCompanyInfo = true
      } else {
        this.showAttachCompanyInfo = false
      }
    },
    noTokenToLogin(routerName) {
      const noTokenPage = [
        RouterContanst.USER.REGIST,
        RouterContanst.USER.FORGET_PWD,
        RouterContanst.USER.PRIVACY,
        RouterContanst.USER.ANNOUNCE_FILE,
        RouterContanst.USER.LOGIN,
        RouterContanst.USER.UserServiceAgreement,
        RouterContanst.USER.PrivacyAgreement,
        'otherPayment',
        'publicHelperCenter',
        'ProductEntry',
        'ProductEntryForm',
        'loginModifyPwd'
      ]
      const token = getStorageSync('accessToken')
      if (routerName && !token && noTokenPage.indexOf(routerName) == -1) {
        location.replace('/login?redirect=' + encodeURIComponent(location.href))
      }
    },
    setMainContentHeight(type) {
      if (type == 'all') {
        this.mainContentStyle.minHeight = '100vh'
        return
      }
      const style = {}
      const height = this.$refs?.footer?.$el?.clientHeight || 0
      if (height) {
        style.minHeight = `calc(100vh - 36px - ${height}px)`
        this.mainContentStyle = style
      }
    },
    footerLoadData() {
      this.setMainContentHeight()
    },
    setTitle(route) {
      document.title = route.meta.title || '润药商城'
    },
    loadStorageTime() {
      this.storageTime = localStorage.getItem(this.storageKey)
      if (this.storageTime) {
        this.storageTime = +parseInt(this.storageTime, 10)
        if (this.storageTime <= Math.floor(new Date().getTime() / 1000)) {
          // 如果缓存的时间小于等于当前时间，重新设置
          this.resetCountdown()
        } else {
          // 使用缓存的时间
          this.targetDateInSeconds = this.storageTime
          this.updateCountdown()
        }
      } else {
        // 如果缓存中没有时间，设置为当前时间加上 9 分钟
        this.resetCountdown()
      }
    },
    resetCountdown() {
      // 设置目标时间为当前时间加上 9 分钟
      this.targetDateInSeconds = Math.floor(new Date().getTime() / 1000) + 540 // 9 分钟 = 540 秒
      localStorage.setItem(this.storageKey, this.targetDateInSeconds)
      this.updateCountdown()
    },
    updateCountdown() {
      const now = Math.floor(new Date().getTime() / 1000)
      const timeRemaining = this.targetDateInSeconds - (now + 2)
      if (timeRemaining <= 0) {
        this.resetCountdown()
        const token = getStorageSync('accessToken')
        if (token) {
          this.setAsyncQueryCart()
        }
      } else {
        this.formattedTime = this.formatTime(timeRemaining)
        this.countdown = setTimeout(this.updateCountdown, 3000)
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60)
      const seconds = time % 60
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    },
    setAsyncQueryCart() {
      const tempObj = getCartFieldData(this.selectedProductList)
      const prams = {
        selectedProductList: tempObj?.selectedProductList || [],
        presentProductList: tempObj?.presentProductList || [],
        cancelProductList: tempObj?.cancelProductList || []
      }
      asyncQueryCart(prams)
    }
  }
}
</script>
<style lang="less">
html {
  height: 100%;
}

body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  min-width: 1200px;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  // background: #F5F5F5;
}

.header {
  height: 60px;
}

.content {
  flex: 1;
}

.footer {
  height: 120px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main-content {
  width: 100%;
  background-color: #F5F5F5;
  position: relative;
}

.loginPage {
  margin-top: -20px;

  .ivu-form-item {
    margin-bottom: 32px;
  }
}
</style>
