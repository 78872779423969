<template>
  <div class="user-footer-wrapper">
    <div class="row-type">
      <!-- 2023-8-26商家入驻是常态入口不需要任何判断条件 RYSC2-5019 -->
      <span class="item">
        <span @click="toEntry">商家入驻</span>
      </span>
      <span v-for="(item,index) in certificateList" :key="index" class="item" @click="seeDetail(item)">
        <template>
          <span class="line">｜</span>
          <span>{{ item.fileShowName }}</span>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import { getAgreementList } from '@/common/service'
const agreementParam = ['privacy_policy', 'service_agreement']
import { setSessionStorageSync, getSessionStorageSync } from '@/common/utils/storage'
export default {
  name: 'UserFooter',
  data() {
    return {
      allCode: [
        // 'privacy_policy', // 隐私政策
        // 'service_agreement', // 服务协议
        // 'business_license',
        // 'tele_business_license',
        // 'internet_service_license',
        // 'medical_three_license',
        // 'icp_record',
        // 'sale_rule',
        // 'deliver_rule',
        // 'dispute_deal_rule',
        'pc_version_declare',
        'pc_sys_safe_protect',
        'pc_icp_record',
        'pc_tele_business_license',
        'pc_user_agreement',
        'pc_medical_three_license',
        'pc_privacy_policy',
        'pc_net_device',
        'pc_quality_safe',
        'pc_service_line',
        'pc_business_license'
      ],
      certificateList: []
    }
  },
  computed: {
    getShowList() {
      return this.certificateList.filter(item => !agreementParam.includes(item.fileType))
    },
    getAgreement() {
      return this.certificateList.filter(item => agreementParam.includes(item.fileType))
    }
  },
  async created() {
    const userFooterAgreementList = getSessionStorageSync('userFooterAgreementList')
    if (userFooterAgreementList) {
      this.certificateList = userFooterAgreementList
    }
    const res = await getAgreementList(['pc_version_declare',
      'pc_sys_safe_protect',
      'pc_icp_record',
      'pc_tele_business_license',
      'pc_user_agreement',
      'pc_medical_three_license',
      'pc_privacy_policy',
      'pc_net_device',
      'pc_quality_safe',
      'pc_service_line',
      'pc_business_license'])
    // 上报接口页面请求成功，给app.vue动态计算footer高度用
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit('loadData')
      }, 0)
    })
    this.certificateList = res.data
    setSessionStorageSync('userFooterAgreementList', res.data)
  },
  methods: {
    seeDetail(item) {
      sessionStorage.setItem('content', item.fileContent)
      window.open(`/user/cmsFileDetail?fileType=${item.fileType}&id=${item.id}`, '_blank')
    },
    toEntry() {
      window.open(`/ProductEntry`, '_blank')
      // this.$router.push(`/ProductEntry`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
//$grayTxt: rgba(0, 0, 0, 0.6);
.cmsFileDetail-wrapper ~ .user-footer-wrapper {
  background: #F5F5F5;
}
.user-footer-wrapper {
  padding: 32px 0;
  background: #fff;
  .row-type {
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
    .item {
      cursor: pointer;
    }
    span {
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
    .line {
      margin: 0 10px;
    }
  }
}
</style>
