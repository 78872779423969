<template>
  <div class="coupon">
    <div class="head">
      <div style="background-color: #fff;">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true" />
        <allProductClass selected-type="couponCenter" />
      </div>
      <div style="width: 100%; background-color: #f5f5f5; padding-top: 16px;">
        <img style="width: 1200px; display: block; margin: 0px auto;" src="@/assets/images/coupon/coupon-banner.png" alt="">
        <Coupon :show-tab="false" style="width: 1200px;margin: 0 auto;" @loadTime="getLoadTime"/>
      </div>
    </div>
  </div>
</template>
<script>
import { getFilePath } from '@/common/utils/base'
import { getAgreementList } from '@/common/service'
import allProductClass from '@/views/home/coms/allProductClass.vue' // 全部商品分类
import SearchBar from '@/components/SearchBar.vue'
import Tabs from '@/components/Tabs'
import Coupon from '@/views/personnal/coupon/index.vue'
import { cmsLink } from '@/views/home/index'
export default {
  name: '',
  components: {
    SearchBar, Tabs, Coupon, allProductClass
  },
  data() {
    return {
      getFilePath,
      listArr: [],
      pageNum: 1,
      isReq: true,
      total: 0,
      searchValue: '',
      pageData: {},
      showPageNum: true,
      loadTime: 0
    }
  },
  created() {
    this.getList()
    this.loadTime = new Date().getTime()
  },
  mounted() {
    this.$zhuge.track('领券中心-进入领券中心页')
  },
  methods: {
    toDetail(item) {
      // uni.navigateTo({
      //   url: `/pages/explain/notice/component/noticeDetail?id=${id}`
      // })
      sessionStorage.setItem('content', item.fileContent)
      window.open('/user/cmsFileDetail?fileType=' + item.fileType + '&id=' + item.id)
    },
    async getList() {
      // let param = {
      //   condition: {
      //     noticeStatus: "issue",
      //     keywords: this.searchValue
      //   },
      //   descs: [
      //     "sorted",
      //     "create_time"
      //   ],
      //   pageNum: this.pageNum,
      //   pageSize: 10
      // }
      const { data } = await getAgreementList(['pc_version_declare',
        'pc_sys_safe_protect',
        'pc_icp_record',
        'pc_tele_business_license',
        'pc_user_agreement',
        'pc_medical_three_license',
        'pc_privacy_policy',
        'pc_net_device',
        'pc_quality_safe',
        'pc_service_line',
        'pc_business_license'])
      this.listArr = []
      if (data.length > 0) {
        this.listArr.push(...data)
        return
      }
      this.isReq = false
    },
    searchHandler() {
      this.getList()
    },
    tabsChangehandler(data) {
      if (data?.name == '全部商品') {
        this.$router.push('/search/list?type=homeShop')
      } else {
        cmsLink.bind(this)(data.link)
      }
    },
    getLoadTime() {
      if (this.loadTime) {
        console.log(new Date().getTime() - this.loadTime)
        this.$zhuge.track('浏览页面', {
          页面标题: '领券中心',
          页面请求数量: 6,
          页面加载时长: new Date().getTime() - this.loadTime,
          page_url: this.$route.path
        })
        this.loadTime = 0
      }
    }
  }
}
</script>
<style scoped lang='less'>
  .coupon {
    height: 100%;
    // overflow: hidden;
    .info_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-style: solid;
      border-color: #E7E7E7;
      border-width: 0 0 1px 0;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
      .title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        flex: 1;
      }
      .time {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-left: auto;
      }
    }
  }
  .search-warp {
    width: 1200px;
    margin: 40px auto 0;
    display: flex;
    justify-content: flex-end;
  }
  .search {
    // float: right;
    margin-left: auto;
  }
  //::v-deep .content-wrapper {
  //  padding: 16px 0 !important;
  //}
</style>

<!-- pages/explain/notice/index -->
