<template>
  <div class="customer-steps">
    <div
      v-for="(item, index) in nodes"
      :key="index"
      :class="{ success: item.approvalState === 'auth', waiting: item.approvalState === 'under_auth', error: item.approvalState === 'refuse_auth' }"
      class="customer-steps-item"
    >
      <div class="customer-steps-item-name">
        {{ item.nodeName }}
        <span class="dot" />
        <span v-if="item.approvalName">{{ item.approvalName }}</span>
      </div>
      <div class="customer-steps-item-description">
        <p>{{ item.updateTime ? item.updateTime : item.approvalName }}</p>
      </div>
      <div v-if="rejectReason && index === nodes.length - 1" class="remark">拒绝原因：{{ rejectReason }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomerSteps',
  props: {
    nodes: {
      type: Array,
      default: () => {}
    },
    rejectReason: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="less">
.customer-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  padding-top: 15px;
  &-item {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 20px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 25px;
      width: 1px;
      height: 85%;
      // background-color: rgba(236, 236, 236, 1);
      border-right: 1px dashed rgba(236, 236, 236, 1);
    }
    &::before {
      content: '';
      position: absolute;
      left: 4px;
      top: 5px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      border: rgba(236, 236, 236, 1) 1px solid;
      background-color: rgba(255, 255, 255, 1);
    }
    &:last-child {
      &::after {
        width: 0;
        border: none;
      }
    }
    &.success {
      &::after {
        background-color: #349425;
      }
      &::before {
        border-color: #349425;
        background-color: #349425;
      }
    }
    &.waiting {
      &::after {
        // background-color: #69BD54;
      }
      &::before {
        border-color: #dcdcdc;
        background-color: #fff;
      }
    }
    &.error {
      &::after {
        background-color: #cb272d;
      }
      &::before {
        border-color: #cb272d;
        background-color: #cb272d;
      }
    }
    &-name {
      display: flex;
      gap: 5px;
      align-items: center;
      line-height: 22px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      .dot {
        display: inline-block;
        width: 3px;
        height: 3px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 50%;
      }
    }
    &-description {
    }

    .remark {
      color: var(--font_black-2, rgba(0, 0, 0, 0.6));
      font-family: 'PingFang SC';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
      white-space: normal;
      word-break: break-word;
    }
  }
}
</style>
