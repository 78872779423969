import { get, post, cancelRequest } from '@/common/utils'
import { api } from '@/common/service/config'

// 商品列表
export const productListByEs = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductByEs`,
    data,
    isLoading: true
  })

// 商品列表
export const getSearchSuggest = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/dropDownListProductByEs`,
    data,
    isLoading: true
  })

// 活动专区商品列表
export const productListByEsByActivityId = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductByEsToActivityId`,
    data,
    isLoading: true
  })

// 换购商品last列表
export const listProductReplacementByEsToActivityId = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductReplacementByEsToActivityId`,
    data,
    isLoading: true
  })

// 商品库存
export const productStock = (id) =>
  get({
    url: `${api.baseUrl}/prd/stock/getStockByProductId?productId=${id}`
  })

// 商品分类三级查询
export const productClassify = (data) =>
  post({
    // url:`${api.baseUrl}/prd/product/getClassification`,
    url: `${api.baseUrl}/prd/product/listProductCategoryIdByEs`,
    data,
    isLoading: false
  })

// 搜索结果页商家聚合分类
export const getListOrgCategoryIdByEs = (data) => {
  if (data.orgCategoryId) {
    data.orgCategoryId = [data.orgCategoryId].flat(3)
  }
  return post({
    url: `${api.baseUrl}/prd/product/listOrgCategoryIdByEs`,
    data: data
  })
}

// 商品列表商家
export const productBusiness = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductOrgNameByEs`,
    data,
    isLoading: false
  })

// 厂家列表
export const productFactory = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductFactoryNameByEs`,
    data,
    isLoading: false
  })

// 包装规格
export const productSpe = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductSpecByEs`,
    data,
    isLoading: false
  })

// 标签搜索
export const productLabels = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listProductLabelByEs`,
    data,
    isLoading: false
  })

// 微信小程序-搜索全部商家
export const searchAllBusiness = (data) =>
  post({
    url: `${api.baseUrl}/org/info/searchAll`,
    data: data
  })

// 商家入驻-商家类型
export const getOrgTypeList = () =>
  get({
    url: `${api.baseUrl}/org/info/getOrgTypeList`
  })

// 商家入驻-商家提交
export const orgInfoAddBaseInfo = (data) =>
  post({
    url: `${api.baseUrl}/org/info/add/base/info`,
    data: data
  })

// 商家入驻-商家名称验证
export const orgInfoExistOrgName = (orgName) =>
  get({
    url: `${api.baseUrl}/org/info/existOrgName?orgName=${orgName}`
  })
// 获取历史搜索记录
export const getHistorySearch = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsQuery`,
    data: {}
  })
// 清空搜索历史记录
export const clearHistory = () =>
  get({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsDeleteByUser`
  })
// 添加历史记录
export const addHistorySearch = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsAdd`,
    data: data
  })
// 删除历
export const deleteHistorySearch = (data) =>
  post({
    url: `${api.baseUrl}/user/keywordsHistory/keywordsDelete`,
    data: data
  })

// 查询建采关系
export const getCustRelByCustIdOrgId = (data) =>
  post({
    url: `${api.baseUrl}/cust/custuserrel/getCustRelByCustIdOrgId`,
    data,
    isLoading: false
  })

// 获取商家基础信息
export const getOrgBaseInfo = () =>
  get({
    url: `${api.baseUrl}/org/orginfo/baseInfo`
  })

// 商品列表
export const fetchPriceAndStock = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/fetchPriceAndStock`,
    data,
    isLoading: true
  })

export const cancelPrice = () => cancelRequest(`${api.baseUrl}/prd/product/fetchPrice`)
export const cancelStock = () => cancelRequest(`${api.baseUrl}/prd/product/fetchStock`)

// 查询折后价
export const getEstimatePrice = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/batchEstimatePrice`,
    data,
    isLoading: true
  })

export const cancelEstimatePrice = () => cancelRequest(`${api.baseUrl}/prd/product/batchEstimatePrice`)

export const getProductListByLabelId = (data, params) =>
  post({
    url: `${api.baseUrl}/wx/store/getProductListByLabelId?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
    data,
    isLoading: true
  })
// ES批量获取商品价格
export const fetchPrice = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/fetchPrice`,
    data,
    isLoading: true
  })
// ES批量获取商品库存
export const fetchStock = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/fetchStock`,
    data,
    isLoading: true
  })

// ES获取商品详情中精选搭配的商品
export const getRecPurchaseProductListByEs = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/rec-purchase-product/list`,
    data,
    isLoading: true
  })

// 商品分类二级分类查询
export const getClassificationSecond = (activityType) =>
  get({
    url: `${api.baseUrl}/prd/product/getClassificationSecond?activityType=${activityType}`
  })
// 商品为您推荐列表
export const listRecommendProductByEs = (data) =>
  post({
    url: `${api.baseUrl}/prd/product/listRecommendProductByEs`,
    data,
    isLoading: false
  })