<template>
  <div class="goods-change">
    <Modal v-model="goodsChangeModal" :footer-hide="true" :transfer="false" class-name="vertical-center-modal"
      @on-visible-change="modalClose">
      <template #header>
        商品变化提醒
      </template>
      <div class="modal-content">
        <div v-if="batchVerifyList && Object.keys(batchVerifyList).length > 0">
          <div class="price-change">
            <div class="text">以下商品库存数量有变动</div>
            <div v-for="(item, key) in batchVerifyList" :key="key">
              <div class="price-change-box batch-box">
                <div class="good-wrapper">
                  <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                    <div class="img-box">
                      <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                      <span
                        v-if="pItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || pItem.activityType == ACTIVITY_TYPE.LADDERMINUS || pItem.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || pItem.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS"
                        class="tag full-fold">满减</span>
                      <span v-if="pItem.activityType == ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present == true"
                        class="tag replacement">换购品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present != true"
                        class="tag full-fold">满换</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present == true"
                        class="tag replacement">赠品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present != true"
                        class="tag full-fold">满赠</span>
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.SPECIAL" class="calculate"
                        src="@/assets/images/car/calculate.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.NEARTERM" class="calculate"
                        src="@/assets/images/car/near-expiry-date.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.FIXED_PRICE" class="calculate"
                        src="@/assets/images/car/fixed-price.png" alt="">
                      <span
                        v-if="[ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(pItem.activityType)"
                        class="tag full-fold">组合购</span>
                    </div>
                    <span class="name">{{ pItem.productName ? pItem.productName : '' }}</span>
                  </div>
                </div>
                <div class="change-tip">剩余可售库存:{{ item.totalStock }},请修改加购数量</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="batchLimitList && Object.keys(batchLimitList).length > 0">
          <div class="price-change">
            <div class="text">以下商品限购数量有变动</div>
            <div v-for="(item, key) in batchLimitList" :key="key">
              <div class="price-change-box batch-box">
                <div class="good-wrapper">
                  <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                    <div class="img-box">
                      <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                      <span
                        v-if="pItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || pItem.activityType == ACTIVITY_TYPE.LADDERMINUS || pItem.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || pItem.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS"
                        class="tag full-fold">满减</span>
                      <span v-if="pItem.activityType == ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present == true"
                        class="tag replacement">换购品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present != true"
                        class="tag full-fold">满换</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present == true"
                        class="tag replacement">赠品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present != true"
                        class="tag full-fold">满赠</span>
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.SPECIAL" class="calculate"
                        src="@/assets/images/car/calculate.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.NEARTERM" class="calculate"
                        src="@/assets/images/car/near-expiry-date.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.FIXED_PRICE" class="calculate"
                        src="@/assets/images/car/fixed-price.png" alt="">
                      <span
                        v-if="[ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(pItem.activityType)"
                        class="tag full-fold">组合购</span>
                    </div>
                    <span class="name">{{ pItem.productName ? pItem.productName : '' }}</span>
                  </div>
                </div>
                <div class="change-tip">剩余限购数量:{{ item.productResidual < 0 ? 0 : item.productResidual }},请修改加购数量</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activityLimit && Object.keys(activityLimit).length > 0">
            <div class="price-change batch-box">
              <div class="text">以下商品限购数量有变动</div>
              <div v-for="(item, key) in activityLimit" :key="key">
                <div class="price-change-box">
                  <div class="good-wrapper">
                    <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                      <div class="img-box">
                        <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                        <span class="tag replacement"
                          v-if="pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE">换购品</span>
                        <span class="tag replacement"
                          v-if="pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL">赠品</span>
                      </div>
                      <span class="name">{{ pItem.productName ? pItem.productName : '' }}</span>
                    </div>
                  </div>
                  <div class="change-tip">
                    活动限制{{ item.productList[0].activityType == ACTIVITY_TYPE.AUTOEXCHANGE || item.productList[0].activityType == ACTIVITY_TYPE.MANUALEXCHANGE ? '换购' : '赠品' }}数量:{{
                      item.maxPurchase||0 }},请修改加购数量</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="changeGoodsList && Object.keys(changeGoodsList).length > 0">
            <div v-for="(item, key) in changeGoodsList" :key="key" class="price-change">
              <div class="text">{{ getTipTitle(key).tipTxt }}</div>
              <div class="price-change-box">
                <div v-for="(pItem, pIndex) in item.products" :key="pIndex" class="item">
                  <div class="img-box">
                    <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                    <div class="buy-num" v-if="key === 'startLimitChange' || key === 'limitChange'">
                      <span>x{{ pItem.num }}</span>
                    </div>
                    <div class="buy-tip" v-if="key === 'stockOut' || key === 'soldOut' || key === 'noPurchase'">
                      <span>{{ getTipTitle(key).tip }}</span>
                    </div>
                  </div>
                  <div class="name">{{ pItem.productName ? pItem.productName : '' }}</div>
                  <div v-if="key === 'priceChange'" class="price">¥{{ returnFloat(pItem.actualPrice) }}</div>
                  <span v-if="key === 'stockChange'" class="quantum">剩余可售库存：{{ pItem.availableNum }}</span>
                  <div v-if="key === 'startLimitChange'" class="quantum">起购数量：{{ pItem.startBuyNum }}</div>
                  <div v-if="key === 'limitChange'" class="quantum">{{ getTip(pItem) }}</div>
                  <div v-if="key === 'unpriced'" class="price">未定价</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="productStockCheckList && productStockCheckList.length > 0">
            <div class="price-change">
              <div class="text">以下商品库存数量有变动</div>
              <div class="price-change-box batch-box">
                <div class="good-wrapper">
                  <div v-for="(pItem, pIndex) in productStockCheckList" :key="pIndex" class="item">
                    <div class="img-box">
                      <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                      <span
                        v-if="pItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || pItem.activityType == ACTIVITY_TYPE.LADDERMINUS || pItem.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || pItem.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS"
                        class="tag full-fold">满减</span>
                      <span v-if="pItem.activityType == ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present == true"
                        class="tag replacement">换购品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present != true"
                        class="tag full-fold">满换</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present == true"
                        class="tag replacement">赠品</span>
                      <span
                        v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present != true"
                        class="tag full-fold">满赠</span>
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.SPECIAL" class="calculate"
                        src="@/assets/images/car/calculate.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.NEARTERM" class="calculate"
                        src="@/assets/images/car/near-expiry-date.png" alt="">
                      <img v-if="pItem.activityType == ACTIVITY_TYPE.FIXED_PRICE" class="calculate"
                        src="@/assets/images/car/fixed-price.png" alt="">
                      <span
                        v-if="[ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(pItem.activityType)"
                        class="tag full-fold">组合购</span>
                    </div>
                    <span class="name">{{ pItem.productName ? pItem.productName : '' }}</span>
                    <div class="change-tip">剩余可售库存:{{ pItem.num }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="combinationStockCheckList && combinationStockCheckList.length > 0">
            <div
              v-if="!(batchVerifyList && Object.keys(batchVerifyList).length > 0) && !(productStockCheckList && productStockCheckList.length > 0) && !(changeGoodsList && Object.keys(changeGoodsList).length > 0)"
              class="text">以下商品库存数量有变动</div>
            <div v-for="(item, key) in combinationStockCheckList" :key="key" class="price-change">
              <div class="price-change-box combine-change-box">
                <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                  <div class="img-box">
                    <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                    <span class="tag full-fold">组合购</span>
                  </div>
                  <div class="name">{{ pItem.productName ? pItem.productName : '' }}</div>
                </div>
                <div class="change-tip w-text-align-center">组合活动剩余购买套数:{{ item.expandMap?.maxUnits || 0 }}</div>
              </div>
            </div>
          </div>
          <!-- 商品总库存限购 新逻辑 -->
          <div v-if="batchStockCheckList && batchStockCheckList.length > 0">
            <div class="price-change">
              <div class="text">以下商品库存数量有变动</div>
              <div v-for="(item, key) in batchStockCheckList" :key="key">
                <div class="price-change-box batch-box">
                  <div class="good-wrapper">
                    <div v-for="(pItem, pIndex) in item.productList" :key="pIndex" class="item">
                      <div class="img-box">
                        <img :src="pItem.productLogo ? getFilePath('PS_01', pItem.productLogo) : defaultImg" alt="">
                        <span
                          v-if="pItem.activityType == ACTIVITY_TYPE.CYCLEMINUS || pItem.activityType == ACTIVITY_TYPE.LADDERMINUS || pItem.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS || pItem.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS"
                          class="tag full-fold">满减</span>
                        <span v-if="pItem.activityType == ACTIVITY_TYPE.DISCOUNT" class="tag full-fold">满折</span>
                        <span
                          v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present == true"
                          class="tag replacement">换购品</span>
                        <span
                          v-if="(pItem.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || pItem.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) && pItem.expandMap.present != true"
                          class="tag full-fold">满换</span>
                        <span
                          v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present == true"
                          class="tag replacement">赠品</span>
                        <span
                          v-if="(pItem.activityType == ACTIVITY_TYPE.GIFT_AUTO || pItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL) && pItem.expandMap.present != true"
                          class="tag full-fold">满赠</span>
                        <img v-if="pItem.activityType == ACTIVITY_TYPE.SPECIAL" class="calculate"
                          src="@/assets/images/car/calculate.png" alt="">
                        <img v-if="pItem.activityType == ACTIVITY_TYPE.NEARTERM" class="calculate"
                          src="@/assets/images/car/near-expiry-date.png" alt="">
                        <img v-if="pItem.activityType == ACTIVITY_TYPE.FIXED_PRICE" class="calculate"
                          src="@/assets/images/car/fixed-price.png" alt="">
                        <span
                          v-if="[ACTIVITY_TYPE.FIXEDCOMBINATION, ACTIVITY_TYPE.CUSTOMCOMBINATION].includes(pItem.activityType)"
                          class="tag full-fold">组合购</span>
                      </div>
                      <span class="name">{{ pItem.productName ? pItem.productName : '' }}</span>
                      <!-- <div class="change-tip">剩余可售库存:{{ item.totalStock }}</div> -->
                    </div>
                  </div>
                  <div class="change-tip">剩余可售库存:{{ item.totalStock }},请修改加购数量</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span v-if="!(isGroupBuy && checkType === 'orderConfirm')" class="back-car" :class="{ active: cannotSettle || isNeedGoBack }" @click="close">
            {{ btnLeftText }}
          </span>
          <span v-if="!cannotSettle && !isNeedGoBack && checkType != 'orderConfirm'" class="continue" @click="goSettle">{{
            btnRightText }}</span>
          <span v-if="checkType === 'orderConfirm' && (batchStockCheckList && batchStockCheckList.length < 1)" class="continue" @click="continueSubmit">继续提单</span>
        </div>
    </Modal>
  </div>
</template>

<script>
import { returnFloat, ACTIVITY_TYPE, getFilePath, getLimitInfo } from '@/common/utils'
import { mapMutations } from 'vuex'
export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    checkGoodType: {
      type: String,
      default: '' // goodChange goodPackageMail continueSettle orderConfirm
    },
    batchVerifyList: {
      type: Object,
      default: () => { }
    },
    changeGoodsList: {
      type: Object,
      default: () => { }
    },
    validCartList: {
      type: Array,
      default: () => []
    },
    originBatchVerifyList: {
      type: Object,
      default: () => { }
    },
    batchLimitList: {
      type: Object,
      default: () => { }
    },
    activityLimit: {
      type: Object,
      default: () => { }
    },
    originActivityLimit: {
      type: Object,
      default: () => { }
    },
    changePackageMailList: {
      type: Object,
      default: () => { }
    },
    productStockCheckList: {
      type: Array,
      default: () => []
    },
    combinationStockCheckList: {
      type: Array,
      default: () => []
    },
    batchStockCheckList: {
      type: Array,
      default: () => []
    },
    isGroupBuy: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      defaultImg: require('@/assets/images/common/good_default.png'),
      goodsChangeModal: false,
      checkType: '',
      getFilePath,
      ACTIVITY_TYPE: ACTIVITY_TYPE,
      returnFloat: returnFloat,
      isCloseModal: false, // 是否关闭弹框
    }
  },
  computed: {
    isNeedGoBack() {
      const falg = this.batchVerifyList && Object.keys(this.batchVerifyList).length > 0 || (this.activityLimit && Object.keys(this.activityLimit).length > 0) || (this.batchLimitList && Object.keys(this.batchLimitList).length > 0)
      console.log(falg)
      return falg
    },
    btnLeftText() {
      return this.checkType === 'goodPackageMail' || (this.checkType === 'continueSettle' && this.batchStockCheckList.length > 0) || this.checkType === 'orderConfirm' ? '返回购物车' : '我知道了'
    },
    btnRightText() {
      return '继续结算'
    },
    cannotSettle() {
      if (this.checkType === 'goodPackageMail') {
        for (const key in this.changePackageMailList) {
          if (key === 'delivery') {
            let num = 0
            const arrIds = []
            if (this.validCartList?.length > 0) {
              for (const sItem of this.validCartList) {
                for (const aItem of sItem?.activityList) {
                  for (const gItem of aItem?.productList) {
                    if (gItem.checked == true && !arrIds.includes(sItem.storeId)) {
                      num++
                      arrIds.push(sItem.storeId)
                      break
                    }
                  }
                }
              }
            }
            const flag = this.changePackageMailList[key].every(item => item.allowed == true)
            // console.log('num',this.changePackageMailList,this.changePackageMailList[key].length,num,flag)
            return flag && this.changePackageMailList[key].length == num
          } else {
            return false
          }
        }
      } else if (['continueSettle', 'queryCartGoodChange'].includes(this.checkType)) {
        return true
      } else if (this.batchStockCheckList.length > 0) {
        return true
      } else if (this.changeGoodsList && Object.keys(this.changeGoodsList).length > 0) {
        if (Object.keys(this.changeGoodsList).includes('startLimitChange')) {
          return true
        }
      } else {
        return false
      }
      return false
    }
  },
  watch: {
    showPopup(newVal) {
      this.goodsChangeModal = newVal
    },
    checkGoodType(newVal) {
      this.checkType = newVal
    },
  },
  methods: {
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    getTip(pItem) {
      return getLimitInfo(pItem)
    },
    modalClose(e) {
      if (!this.isCloseModal && !e) {
        console.log('modalClose')
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        if (['continueSettle'].includes(this.checkGoodType)) {
          if (this.productStockCheckList.length > 0 || this.combinationStockCheckList.length > 0) {
            // this.$bus.$emit('getConfirmOrderInfo')
          } else {
            this.$router.push({
              path: `/car`
            })
          }
          return
        } if (['orderConfirm'].includes(this.checkGoodType)) {
          this.$router.push({
            path: `/car`
          })
          return
        } else {
          // 购物车页面关闭弹窗
          console.log('this.setSelectedProductList', this.validCartList)
          this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
          this.$bus.$emit('getCartListFn')
        }
      }
    },
    close() {
      this.isCloseModal = true
      this.goodsChangeModal = false
      this.$emit('update:show-popup', this.goodsChangeModal)
      console.log("xxx", this.checkGoodType)
      if (['continueSettle'].includes(this.checkGoodType)) {
        console.log("xxx222", this.checkGoodType)
        if (this.productStockCheckList.length > 0 || this.combinationStockCheckList.length > 0) {
          // this.$bus.$emit('getConfirmOrderInfo')
        } else {
          this.$router.push({
            path: `/car`
          })
        }
        return
      } if (['orderConfirm'].includes(this.checkGoodType)) {
        this.$router.push({
          path: `/car`
        })
        return
      } else {
        // 购物车页面关闭弹窗
        console.log('this.setSelectedProductList', this.validCartList)
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
        this.$bus.$emit('getCartListFn')
      }
    },
    goSettle() { // 结算页面继续提交订单
      if (this.checkType === 'goodPackageMail') {
        // 购物车页面弹窗展示去结算
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击继续结算按钮", {})
      } else {
        this.dealJumpSettle()
      }
    },
    dealJumpSettle() {
      // 店铺起配包邮校验
      if (Object.keys(this.changePackageMailList).length > 0) {
        // 店铺起配包邮校验
        this.goodsChangeModal = false
        this.$emit('update:show-popup', this.goodsChangeModal)
        this.$emit('checkGoodPackageMail', this.changePackageMailList)
        // this.$u.vuex('selectedProductList', JSON.parse(JSON.stringify(this.validCartList)))
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.validCartList)))
      } else {
        // 去结算
        this.$router.push({
          path: '/trade/orderConfirm'
        })
        this.$zhuge.track("购物车-点击继续结算按钮", {})
      }
    },
    getTipTitle(key) {
      let tipTxt = '', tip = ''
      switch (key) {
        case 'priceChange':
          tipTxt = '以下商品价格有变动：'
          break
        case 'unpriced':
          tipTxt = '以下商品未定价：'
          break;
        case 'stockChange':
          tipTxt = '以下商品库存数量有变动：'
          break
        case 'stockOut':
          tipTxt = '以下商品缺货：'
          tip = '售罄'
          break
        case 'soldOut':
          tipTxt = '以下商品已下架：'
          tip = '已下架'
          break
        case 'noPurchase':
          tipTxt = '以下商品无采购权限：'
          tip = '无权限采购'
          break
        case 'startLimitChange':
          tipTxt = '以下商品起购数量有变动：'
          break
        case 'limitChange':
          tipTxt = '以下商品限购数量有变动：'
          break
        default:
          break
      }
      return {
        tipTxt,
        tip
      }
    },
    // 继续提单
    continueSubmit() {
      console.log('continueSubmit')
      this.isCloseModal = true
      this.goodsChangeModal = false
      this.$emit('update:show-popup', this.goodsChangeModal)
      this.$emit('getConfirmOrderInfo')
    },
  }
}
</script>

<style scoped lang="less">
.goods-change {
  ::v-deep .vertical-center-modal {
    .ivu-modal {
      width: 614px !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      max-height: calc(100% - 100px) !important;

      .ivu-modal-content {
        .ivu-modal-header {
          border-bottom: none;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
        }

        .ivu-modal-body {
          padding: 0px 0px 20px 24px;
          box-sizing: border-box;

          .modal-content {
            max-height: calc(100vh - 230px);
            padding: 0 20px 20px 0;
            overflow-y: auto;
          }

          .price-change {
            .text {
              font-size: 14px;
              line-height: 24px;
              color: #000000;
            }
          }

          .price-change-box {
            display: flex;
            flex-wrap: wrap;
            padding: 12px 0px;

            .item {
              width: 129px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 12px;
              margin-bottom: 12px;

              &:nth-child(4n + 0) {
                margin-right: 0;
              }

              .img-box {
                flex-shrink: 0;
                width: 129px;
                height: 129px;
                border-radius: 4px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 86px;
                  height: 86px;
                }

                .calculate {
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  width: 60px;
                  height: auto;
                }

                .tag {
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 2px 4px;
                  box-sizing: border-box;
                  color: #F53F3F;
                  border-radius: 4px;
                  font-weight: 500;
                  font-size: 8px;
                }

                .full-fold {
                  background-color: #FEF5F5;
                  border: 1px solid #F53F3F;
                }

                .special {
                  color: #f99d33;
                  background: rgba(114, 46, 209, 0.05);
                  border: 1px solid #f99d33;
                }

                .replacement {
                  color: #722ED1;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                }

                .lose-cause {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 60px;
                  height: 60px;
                  background-color: rgba(0, 0, 0, 0.4);
                  color: #fff;
                  font-size: 14px;
                  border-radius: 50%;

                  span {
                    width: 45px;
                    text-align: center;
                  }
                }

                .number {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  color: #CB272D;
                }

                .buy-num {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  bottom: 2px;
                  right: 2px;
                  width: 20px;
                  height: 18px;
                  background: rgba(0, 0, 0, 0.6);
                  border-radius: 4px;

                  span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 18px;
                    color: #FFFFFF;
                  }
                }

                .buy-tip {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  transform: translate(-50%, -50%);
                  left: 50%;
                  top: 50%;
                  width: 60px;
                  height: 60px;
                  text-align: center;
                  padding: 8px;
                  box-sizing: border-box;
                  background: rgba(0, 0, 0, 0.4);
                  border-radius: 50%;

                  span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    color: #FFFFFF;
                  }
                }
              }

              .name {
                width: 100%;
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 4px;
              }

              .price {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #cb272d;
                margin-top: 4px;
              }

              .quantum {
                width: 100%;
                font-size: 12px;
                line-height: 20px;
                color: #cb272d;
              }
            }

            &.combine-change-box {
              background-color: rgba(245, 245, 245, 1);
              padding: 8px;
              border-radius: 4px;
              margin-top: 12px;

              .item {
                width: 128px;
                margin-right: 8px;

                &:nth-child(4n + 0) {
                  margin-right: 0;
                }
              }
            }
          }

          .batch-box {
            display: block;
            padding: 12px 0px;

            .good-wrapper {
              display: flex;
              flex-wrap: wrap;
            }
          }

          .modal-footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            margin-right: 24px;
            cursor: pointer;

            span {
              padding: 5px 16px;
              border-radius: 4px;
              font-size: 14px;
              line-height: 22px;
              margin-left: 8px;
            }

            .back-car {
              color: rgba(0, 0, 0, 0.9);
              border: 1px solid rgba(220, 220, 220, 1);

              &.active {
                background-color: #f99d33;
                color: rgba(255, 255, 255, 0.9);
                border: 1px solid #f99d33;
              }
            }

            .continue {
              background-color: #f99d33;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }
}

.change-tip {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(245, 63, 63, 1);
}
</style>
